export default {
    topClass: '顶级分类',
    thisWeek: '近七天',
    thisMonth: '近一月',
    fullYear: '近一年',
    salesMoney: '销售额',
    salesMoneyTrend: '销售额趋势',
    captchaPh: '[s]秒后再获取',
    submitAudit: '提交审核',
    unit: '单位',
    ge: '个',
    tiji: '体积',
    weight: '重量',
    viewStoreCategory: '查看店铺分类',
    goodsBrandPh: '支持选择10项分类',
    editGoodsDetail: '编辑商品详情',
    goodsBrand: '商品品牌',
    storeCategory: '店铺分类',
    prevStep: '上一步',
    putInRepository: '放入仓库',
    maintainKeep: '售后保障',
    sameFee: '统一运费',
    transportTemplate: '运费模板',
    sendTime: '发货时间',
    sendTimeA: '发货时间：',
    forgotPassword: '忘记密码',
    transportFee: '运费',
    skuWarnPh: '当商品总库存达到预警值，库存状态为预警状态；当商品库存为0时，商品下架到仓库中',
    skuWarn: '库存预警值',
    salesCountPh: '若不填写，则根据实际销量展示',
    sku: '库存',
    exitsSameValue: '已存在相同的值',
    weightG: '重量（g)',
    chengben: '成本价（元）',
    priceYuanA: '价格（元）',
    specificationsImagePh: '建议尺寸750*750像素',
    specificationsImage: '规格图',
    specificationsDetail: '规格明细',
    addSpecifications: '添加规格',
    addSpecificationsValue: '添加规格值',
    specificationsValue: '规格值',
    specificationsName: '规格名称',
    goodsSpecifications: '商品规格',
    favor: '收藏',
    repository: '仓库中',
    resubmit: '重新提交',
    auditStatusA: '审核状态',
    goodsClassify: '商品分类',
    goodsManage: '商品管理',
    unAudit: '待审核',
    auditRejected: '审核拒绝',
    canBackScore: '可返计分：',
    paiedMoneyA: '实付款：',
    postFee: '邮费：',
    includePostFee: '含邮费：',
    goodsTotalPrice: '商品总价：',
    payOptions: '付款方式',
    payTime: '付款时间',
    orderInfo: '订单信息',
    concater: '联系人',
    receiveAddressA: '收货地址',
    agreeMaintain: '同意售后',
    agree: '同意',
    selectTransport: '选择物流',
    transportNo: '物流单号',
    sendGoods: '发货',
    today: '今天',
    near7Days: '近7天',
    near30Days: '近30天',
    priceA: '单价',
    unConfirm: '待确认',
    payOnline: '线上支付',
    orderTimeA: '下单时间',
    cancelRecommend: '取消推荐',
    deleteRejected: '删除驳回',
    deleteAuding: '删除审核中',
    recommend: '推荐',
    applyDelete: '申请删除',
    sellerReplyContent: '卖家回复内容',
    commentImage: '评价图片',
    commentContent: '评价内容',
    rate: '评星',
    orderNoA: '订单号',
    rateFilter: '星级筛选',
    commentTime: '评价时间',
    fiveStars: '五星',
    fourStars: '四星',
    threeStars: '三星',
    twoStars: '二星',
    oneStars: '一星',
    noStars: '无评星',
    usernameA: '用户名称',
    skuOrSpecifications: '库存/规格',
    mulImagePh: '（多图片上传5张，第一张为商品列表缩略图，建议尺寸750*750像素）',
    originPrice: '原价',
    reSelectClass: '重新选择分类',
    selectedClass: '已选分类',
    pleaseSelectClass: '请选择分类',
    nextStep: '下一步',
    baseinfo: '基本信息',
    goodsDetail: '商品详情',
    selectClass: '选择分类',
    moveUp: '上移',
    moveDown: '下移',
    top: '顶置',
    cancelTop: '取消顶置',
    postStatus: '上架状态',
    sellCount: '销量',
    comment: '评论',
    clickCount: '点击量',
    totalSku: '总库存',
    sellPrice: '售价',
    goodsMainImage: '商品主图',
    selling: '出售中',
    classify: '分类',
    remove: '移除',
    editClass: '编辑分类',
    belongsClass: '所属分类',
    priceYuan: '售价（元）',
    batchClassify: '批量分类',
    parentClassA: '父级分类',
    parentClass: '上级分类',
    className: '分类名称',
    selectFirstClass: '选择一级分类',
    publishGoods: '发布商品',
    yes: '是',
    no: '否',
    goodsTitle: '商品标题',
    updateTime: '更新时间',
    days: '天数',
    date: '日期',
    canWithdraw: '可提现',
    withdrawServiceCharge: '提现手续费',
    goWrite: '去填写',
    writeBankcard: '您还未填写银行卡信息',
    platformFeedback: '平台反馈',
    auditTime: '审核时间',
    postTime: '提交时间',
    withdrawAccount: '提现账户',
    serviceCharge: '手续费',
    withdrawMoney: '提现金额',
    withdrawedMoney: '已提现金额：',
    freezeMoney: '冻结金额：',
    canWithdrawMoney: '可提现金额：',
    withdraw: '提现',
    withdrawTime: '提现时间',
    withdrawFailed: '提现失败',
    passed: '已通过',
    pleaseSelect: '请选择',
    withdrawStatus: '提现状态',
    orderNoA: '订单号',
    earnMoney: '入账金额',
    repay: '退款',
    percentage: '平台抽成',
    earn: '收入',
    earnTime: '入账时间',
    earnType: '收支类型',
    concatType: '联系方式',
    orderBuyer: '下单用户',
    picPH: '建议尺寸宽260*高150像素',
    coverImage: '封面图',
    relationGoods: '关联商品',
    partyName: '活动名称',
    partyManage: '活动管理',
    removeGoods: '移除商品',
    addGoods: '添加商品',
    checkedGoods: '已选商品',
    unsetCouponGoods: '未设置优惠券的商品',
    searchGoods: '搜索商品',
    goodsName: '商品名称',
    couponCanUseGoods: '优惠券可使用商品',
    allGoods: '全部商品',
    partyGoods: '部分商品',
    canUseGoods: '可用商品',
    availableDays: '天内有效',
    couponStart: '领到券当日开始',
    fixedDate: '固定日期',
    unFixedDate: '非固定',
    publish: '上架',
    down: '下架',
    couponStatus: '优惠券状态',
    canUse: '元可使用',
    full: '满',
    limit: '限制',
    noLimit: '不限制',
    useConditionPh: '谨慎设计无门槛，避免资金损失',
    useCondition: '使用门槛',
    mianzhi: '面值',
    yuan: '元',
    discountPh: '优惠券不能抵扣运费',
    discountA: '折扣',
    fullReduce: '满减',
    cheapType: '优惠类型',
    givenCountPh: '-1为不限数量',
    publishTime: '发布时间',
    usedCount: '使用数量',
    gotCount: '领取数量',
    givenCount: '发放数量',
    valueOrDiscount: '面值/折扣',
    description: '描述',
    availableDate: '有效期',
    couponName: '优惠券名称',
    moneySign: '¥[m]',
    bondTime: '缴纳时间',
    expiredTime: '失效时间',
    jiaonaMoney: '缴纳金额',
    payBond: '缴纳保证金',
    payLog: '缴纳记录',
    bondMoney: '保证金额',
    successSelling: '正常运营',
    tempClose: '暂时关闭',
    apiName: '接口名称',
    apiUrl: '接口URL',
    apiCode: '接口代码',
    customerServiceSettings: '客服配置',
    createdBank: '开户行',
    bankCardNo: '银行卡号',
    phone: '手机号',
    companyName: '公司名称',
    name: '姓名',
    bankCardInfo: '银行卡信息',
    storeStatus: '店铺状态',
    choseCountryPh: '请选择国家',
    choseProvincePh: '请选择省份',
    choseCityPh: '请选择城市',
    choseDistrictPh: '请选择区',
    submitToAudit: '提交审核',
    changeAvatar: '更换头像',
    invalidFormat: '[t]格式不正确',
    confirmPassword: '确认密码',
    originPassword: '原密码',
    editInfo: '编辑信息',
    resetPassword: '重置密码',
    manageLicense: '营业执照及行业相关资质证明',
    authBrand: '授权品牌',
    manageClass: '中文经营类目',
    englishManageClass: '英文经营类目',
    arManageClass: '阿拉伯文经营类目',
    storeAddress: '门店地址',
    emailAddress: '邮箱地址',
    concatPhone: '联系电话',
    sellerName: '商户名称',
    storeAvatar: '商户头像',
    replyMessage: '回复留言',
    reply: '回复',
    replyTime: '回复时间',
    replyContent: '回复内容',
    leaveTime: '留言时间',
    leaveMessage: '留言内容',
    delete: '删除',
    required: '[t]不能为空',
    minMax: '[t]的长度为[min]至[max]位',
    min: '[t]的最小长度为[min]位',
    max: '[t]的最大长度为[max]位',
    total: '共[total]条',
    search: '搜索',
    exit: '退出',
    receiveTime: '接收时间',
    messageContent: '消息内容',
    titleA: '标题',
    buyerMessage: '买家留言',
    notifyMessage: '消息通知',
    role: '角色',
    position: '职位',
    rights: '权限',
    enabled: '启用',
    disabled: '禁用',
    askDelete: '您确定要删除【[t]】吗？',
    addRole: '添加角色',
    editRole: '修改角色',
    edit: '编辑',
    status: '状态',
    add: '新增',
    createTime: '创建时间',
    roleName: '角色名称',
    passNoMatch: '两次输入的密码不一致',
    ask: '询问',
    askExit: '您确定要退出吗？',
    newPassword: '新密码',
    captcha: '验证码',
    phoneOrEmail: '手机号/邮箱',
    skuWarn: '库存预警',
    goodsCount: '商品总数',
    gotCount: '已领取',
    couponCount: '优惠券总数',
    notStart: '未开始',
    going: '进行中',
    partyCount: '活动总数',
    allSalesMoney: '总销售额',
    perDaySalesMoney: '日销售额',
    orderCount: '订单总数',
    perDayOrderCount: '日订单数',
    willRepay: '待退款',
    shopManager: '商户后台',
    language: '语言',
    title: '商户后台管理系统',
    zh: '简体中文',
    en: 'English',
    ar: 'العربية',
    moneyMark: '¥',
    paymentOptions: '支付方式',
    paypall: 'PayPall',
    stripe: 'Stripe',
    receivePay: '货到付款',
    qiCard: 'Qi Card',
    discount: '折',
    key: '关键词',
    orderType: '订单类型',
    orderStatus: '订单状态',
    orderTime: '交易时间',
    startTime: '开始时间',
    endTime: '结束时间',
    to: '至',
    allOrder: '所有订单',
    unPay: '待付款',
    unSend: '待发货',
    unReceive: '待收货',
    unAssem: '待评价',
    maintaining: '售后中',
    search: '搜索',
    baby: '宝贝',
    price: '价格',
    count: '数量',
    paiedMoney: '实付款',
    action: '操作',
    orderTime: '订单时间：',
    orderNo: '订单号：',
    orderDetail: '订单详情',
    pay: '付款',
    cancel: '取消',
    applyReback: '申请退款',
    confirmReceive: '确认收货',
    viewTansform: '查看物流',
    viewSendBackTransform: '查看寄回物流',
    confirm: '提交',
    confirmReceived: '确认已收货',
    close: '关闭',
    ok: '确定',
    transformCompany: '物流公司',
    transformCompanyA: '物流公司：',
    transformNoA: '物流单号：',
    afterServiceTime: '售后时间：',
    transformNo: '物流单号',
    concatPhone: '联系电话',
    receiveAddress: '收件地址',
    save: '保存',
    score: '积分',
    sort: '排序',
    reset: '重置',
    uploadImage: '上传图片',
    repayMoney: '退款金额',
    transformInfo: '物流信息',
    remark: '备注：',
    post: '快递',
    back: '返回',
    emailAddress: '邮箱地址',
    storeAddress: '门店地址',
    choseCountryPh: '请选择国家',
    choseProvincePh: '请选择省份',
    choseCityPh: '请选择城市',
    brandAuth: '品牌授权资料',
    auditing: '审核中',
    auditSuccess: '审核成功',
    username: '用户名',
    password: '密码',
    login: '登录',
    getCaptcha: '获取验证码',
    recommendGoods: '推荐商品',
    allGoods: '所有商品',
    askDeleteA: '您确定要删除吗？',
    askDown: '您确定要下架吗？',
    buyer: '买家：',
    speEmpty: '规格不能为空',
    ended: '已结束',
    other: '其他',
    askPost: '您确定要上架吗？',
    unJoinActiveGoods: '未参加当前活动的商品',
    activePrice: '活动价',
    choseGoods: '选择商品',
    rejected: '拒绝',
    shengxiao: '生效中',
    repaying: '申请退款中',
    repied: '已退款',
    askApplyRepay: '您确定要申请退款吗？',
    activeGoods: '活动商品',
    editActivePrice: '编辑活动价',
    setEditActivePrice: '设置 / 编辑活动价',
    specification: '规格',
    activeSku: '活动库存',
    setActivePrice: '设置活动价',
    skuA: '库存：[t]',
    orderManage: '订单管理',
    tradeDone: '交易完成',
    askCancel: '您确定要取消吗？',
    tradeCancel: '交易取消',
    rejectMaintain: '拒绝退款/售后',
    rePaybackReason: '拒绝原因',
    maintainUnsend: '售后通过待寄回',
    rejectReceive: '拒绝收货',
    sendUnConfirm: '寄回待确认',
    appealling: '申诉中',
    appealPassed: '申诉通过',
    repaybacking: '退款中',
    askConfirmReceive: '您已收到商品并确认好了吗？',
    tradeStatus: '交易状态：[s]',
    maintainDesc: '售后描述',
    returnType: '退款类型',
    onlyRepayback: '仅退款',
    returnGoods: '退货退款',
    returnMoney: '退款金额：',
    auditSucess: '审核通过',
    goodsTiltePh: '商品标题最多只能输入200个字',
    unPaied: '未缴纳',
    actionType: '操作类型',
    publishNew: '发布新商品',
    relateGoods: '关联已有商品',
    goodsId: '关联的商品ID',
    goodsIdPh: '请输入要关联的商品ID',
    currency: '货币',
    replyContentEmpty: '回复内容不能为空',
    orderDetailA: '订单明细',
    statistics: '运营概括',
    commentManage: '评价管理',
    withdrawLog: '提现记录',
    shopSettings: '商户设置',
    baseInfo: '基本信息',
    otherSettings: '其他配置',
    bond: '保证金',
    rightsManage: '权限管理',
    rolesManage: '角色管理',
    adminManage: '管理员管理',
    runManage: '运营管理',
    couponManage: '优惠券管理',
    financeManage: '财务管理',
    transformSettings: '物流配置',
    gotoSetTransformTemplate: '还未设置运费模板   去设置',
    coupon: '优惠券：',
    wish: '心愿单',
    currentSettings: '货币配置',
    chineseRelateCurrency: '中文关联货币：',
    englishRelateCurrency: '英文关联货币：',
    arRelateCurrency: '阿拉伯文关联货币：',
    used: '已使用',
    to: '至',
    aRelateGoods: '英文关联商品：',
    bRelateGoods: '阿拉伯文关联商品：',
    chineseRelateCategory: '中文关联商品分类',
    englishRelateCategory: '英文关联商品分类',
    arabRelateCategory: '阿拉伯关联商品分类',
    currencyTip: '请确认选择的币种，确认后无法更改',
    mixPay: '混合支付',
    balancePay: '余额支付'
}