<template>
    <Space class="uploader-wrapper">
        <ASpin :spinning="loading">
            <img :src="url" alt="">
        </ASpin>
        <label>
            {{ $t('changeAvatar') }}
            <input accept="image/*" ref="fileRef" type="file" @change="change">
        </label>
    </Space>
</template>

<script>

export default {
    name: 'SmallImageUploader'
}
</script>

<script setup>
import api from '@/api/user';
import { createUrl } from '@/utils/common';
import { Form } from 'ant-design-vue';
import { ref } from 'vue';
const { onFieldChange } = Form.useInjectFormItemContext();
const props = defineProps(['value']);
/**
 * url链接
 */
const url = ref(props.value);

const emit = defineEmits(['update:value']);
/**
 * loading
 */
const loading = ref(false);
/**
 * 文件引用
 */
const fileRef = ref();

/**
 * 上传事件
 */
const change = async e => {
    const file = e.target.files[0];
    const { type } = file;
    if (!/^image\//.test(type)) {
        return;
    }
    try {
        loading.value = true;
        url.value = createUrl(file);
        const [src] = await api.upload([file]);
        url.value = src;
    } catch (error) {
        url.value = props.value;
    }
    fileRef.value = '';
    loading.value = false;
    emit('update:value', url.value);
    onFieldChange();
}
</script>

<style lang="scss" scoped>
.uploader-wrapper {
    img {
        width: 100px;
        height: 100px;
    }

    label {
        cursor: pointer;
        color: #02A7F0;

        input {
            display: none;
        }
    }
}
</style>