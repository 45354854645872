<template>
    <Dialog width="80%" :title="$t('activeGoods')" ref="dialogRef" @ok="ok">
        <Form :model="searchModel" layout="inline" :options="searchOptions" :loading="loading" reset :submit="$t('search')"
            @submit="searchModel.pageNum = 1; search();" />
        <Space flex align="flex-start">
            <div style="flex:1">
                <Table rowKey="commodityId" :page="searchModel" @pageChange="search" :loading="loading"
                    :rowSelection="{ selectedRowKeys: selectedGoods.map(c => c.commodityId), onChange: unsetChange }"
                    :data="unsetCouponGoodsList" :cols="{
                        commodityName: {
                            title: $t('unJoinActiveGoods'),
                            align: 'left'
                        }
                    }">
                    <template #commodityName="{ text, row }">
                        <div class="goods">
                            <img :src="fi(row.commodityUrls)" width="100" height="100" alt="">
                            <div>
                                <h1>{{ text }}</h1>
                                <Price :price="row.salsePrice" :size="20" color="black" />
                            </div>
                        </div>
                    </template>
                </Table>
                <br>
                <AButton v-t="'addGoods'" type="primary" @click="move"></AButton>
            </div>
            <div style="flex:1">
                <Table rowKey="commodityId"
                    :rowSelection="{ selectedRowKeys: checkedIds, onChange: keys => checkedIds = keys }"
                    :data="checkedGoodsList" :cols="{
                        commodityName: {
                            title: $t('checkedGoods'),
                            align: 'left'
                        }
                    }">
                    <template #commodityName="{ text, row }">
                        <div class="goods">
                            <img :src="fi(row.commodityUrls)" width="100" height="100" alt="">
                            <div>
                                <h1>{{ text }}</h1>
                                <div>
                                    <Price :price="row.salsePrice" :size="20" color="black" />
                                    <AInputNumber readonly :value="row.actPrice" style="width:200px"
                                        :addonBefore="$t('activePrice')" :addonAfter="$t('yuan')" />
                                    <a v-t="'editActivePrice'" style="color:red;" href="javascript:;;"
                                        @click="detail(row)"></a>
                                </div>
                            </div>
                        </div>
                    </template>
                </Table>
                <br>
                <AButton v-t="'removeGoods'" type="primary" @click="removeChecked"></AButton>
            </div>
        </Space>
    </Dialog>
    <Dialog width="80%" :title="$t('setEditActivePrice')" ref="dfPrice" @ok="specOk">
        <Table :cols="{
            commoditySpecFValue: $t('specification'),
            inventory: $t('activeSku'),
            price: $t('setActivePrice')
        }" :data="specList">
            <template #inventory="{ row }">
                <AInputNumber :style="{ width: '200px' }" v-model:value="row.inventory" />
            </template>
            <template #price="{ row }">
                <AInputNumber v-model:value="row.actPrice" style="width:200px" :addonAfter="$t('yuan')" />
            </template>
            <template #commoditySpecFValue="{ text, row }">
                <div style="border:1px solid #ededed; padding: 5px 0;">{{ [text,
                    row.commoditySpecSValue].filter(c => c).join('-') }}
                </div>
                <div style="display: flex; align-items: center; gap: 20px;">
                    <Price :size="18" :price="row.price" color="black" />
                    <span>{{ $t('skuA').replace('[t]', row.inventory) }}</span>
                </div>
            </template>
        </Table>
    </Dialog>
</template>

<script>
export default {
    name: 'GoodsSelectorHasPrice'
}
</script>
<script setup>
// 商品选择器包含价格

import { computed, inject, reactive, ref, watch } from "vue";
import api from '@/api/goods';

const props = defineProps(['value', 'goods']);
const emit = defineEmits(['update:value']);
/**
 * 语言国际化对象
 */
const t = inject('t');
/**
 * dialog引用
 */
const dialogRef = ref();
/**
 * loading
 */
const loading = ref(false);
/**
 * 搜索模型
 */
const searchModel = reactive({
    commodityName: '',
    pageNum: 1,
    pageSize: 10,
    total: 0
});
/**
 * 搜索表单配置项
 */
const searchOptions = computed(() => ({
    commodityName: {
        title: t('goodsName'),
        ph: t('searchGoods'),
        max: 50
    }
}));
/**
 * 已选中的分页模型
 */
const pageModel = reactive({
    pageNum: 1,
    pageSize: 10,
    total: 0
});
/**
 * 对象
 */
const model = ref({});
/**
 * 规格列表
 */
const specList = ref([]);
/**
 * 已选择的商品
 */
const selectedGoods = reactive([]);
/**
 * 未设置优惠券商品列表
 */
const unsetCouponGoodsList = ref([]);
/**
 * 已选商品列表
 */
const checkedGoodsList = ref([]);
/**
 * 已选择的商品选中的ids
 */
const checkedIds = ref([]);
/**
 * 编辑价格引用
 */
const dfPrice = ref();

defineExpose({
    clear: () => {
        checkedGoodsList.value = [];
        checkedIds.value = [];
        selectedGoods.splice(0);
    },
    show: () => dialogRef.value?.show(),
    hide: () => dialogRef.value.hide()
});

watch(() => props.goods, v => {
    v.length && (checkedGoodsList.value = v);
});

/**
 * 未设置更改事件
 */
const unsetChange = keys => {
    for (const goods of unsetCouponGoodsList.value) {
        if (keys.includes(goods.commodityId)) {
            // 如果选中了这个商品，并且列表中没有则加入
            !selectedGoods.some(c => c.commodityId === goods.commodityId) && selectedGoods.push({ ...goods });
        }
        else {
            const index = selectedGoods.findIndex(c => c.commodityId === goods.commodityId);
            index >= 0 && selectedGoods.splice(index, 1);
        }
    }
}

/**
 * 活动详情
 */
const detail = async obj => {
    try {
        const { goods } = props;
        const list = await api.getCommoditySpec(obj.commodityId);
        const { specList: spec } = goods?.find(c => c.commodityId == obj.commodityId) || {};    //查找到的商品
        list.forEach(item => {
            const obj = spec?.find(c => c.specId == item.commoditySpecId);
            obj && (item.actPrice = obj.actPrice);
        });
        specList.value = list;
        model.value = obj;
        dfPrice.value.show();
    } catch (error) {
        console.log(error);
    }
}

/**
 * 搜索
 */
const search = async () => {
    try {
        loading.value = true;
        const { total, data } = await api.saleList({ ...searchModel });
        unsetCouponGoodsList.value = data;
        searchModel.total = total;
    } catch (error) {
        console.log(error);
    }
    loading.value = false;
}

search();

/**
 * 移动
 */
const move = () => {
    for (const item of selectedGoods) {
        if (!checkedGoodsList.value.some(c => c.commodityId === item.commodityId)) {
            checkedGoodsList.value.push({ ...item });
        }
    }
    selectedGoods.splice(0, Infinity);
    pageModel.total = checkedGoodsList.value.length;
}

/**
 * 移除已选中的商品
 */
const removeChecked = () => {
    for (const id of checkedIds.value) {
        const index = checkedGoodsList.value.findIndex(c => c.commodityId === id);
        index >= 0 && checkedGoodsList.value.splice(index, 1);
    }
    pageModel.total = checkedGoodsList.value.length;
    checkedIds.value = [];
}

/**
 * ok事件
 */
const ok = () => {
    emit('update:value', checkedGoodsList.value.filter(c => c.specList?.length).map(c => ({
        commodityId: c.commodityId,
        actPrice: c.actPrice,
        specList: c.specList
    })));
    dialogRef.value.hide();
}

/**
 * 规格设置OK
 */
const specOk = () => {
    const spec = specList.value.filter(c => typeof c.inventory === 'number' && typeof c.actPrice === 'number').map(c => ({
        specId: c.commoditySpecId,
        actPrice: c.actPrice,
        inventory: c.inventory
    }));
    spec.length && (model.value.actPrice = Math.min(...spec.map(c => c.actPrice)));
    model.value.specList = spec;
    dfPrice.value.hide();
}
</script>

<style lang="scss" scoped>
.goods {
    display: flex;
    gap: 10px;

    &>div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100px;
        flex: 1;

        h1 {
            font-size: 16px;
            margin: 0;
        }

        &>div {
            display: flex;
            align-items: center;
            justify-content: space-between;

            :deep .ant-input-number-group-addon {
                background: transparent;
                border: none;
                color: rgb(231, 0, 0);
            }
        }
    }
}
</style>