import { createStore } from 'vuex'
import router from '@/router';
import api from '@/api/shop';

const storePersist = store => {
  /**
   * 存储的key
   */
  const key = 'VUEX_PERSIST_ZAYYA_ADMIN';
  /**
   * 存储器
   */
  const storage = window.sessionStorage;
  /**
   * 已存储的数据
   */
  const data = storage.getItem(key);
  data && store.replaceState(JSON.parse(data));

  /**
   * 注册mutations的回调函数
   */
  store.subscribe((mutation, state) => {
    storage.setItem(key, JSON.stringify(state));
  });
}

export default createStore({
  state: {
    /**
     * 用户信息
     */
    user: {},
    /**
     * 商铺信息
     */
    shop: {}
  },
  getters: {
    /**
     * token
     * @param {*} state 
     * @returns 
     */
    token(state) {
      return state.user.token;
    }
  },
  mutations: {
    /**
     * 设置用户
     */
    setUser(state, payload) {
      state.user = payload;
    },
    /**
     * 设置商铺信息
     */
    setShop(state, payload) {
      state.shop = payload;
    }
  },
  actions: {
    /**
     * 设置其他配置
     */
    async getOtherConfig({ commit }) {
      try {
        const model = await api.otherShopConfig();
        commit('setShop', model);
      } catch (error) { }
    },
    /**
     * 退出登录
     */
    exit({ commit }) {
      commit('setUser', {});
      router.replace({ name: 'login' });
    }
  },
  modules: {
  },
  plugins: [storePersist]
})
