<template>
    <ADatePicker v-bind="$attrs" :locale="$i18n.locale === 'zh' ? zh : $i18n.locale === 'en' ? en : ar">
        <template #renderExtraFooter v-if="$slots.footer">
            <slot name="footer"></slot>
        </template>
    </ADatePicker>
</template>

<script setup>
import zh from 'ant-design-vue/es/date-picker/locale/zh_CN';
import en from 'ant-design-vue/es/date-picker/locale/en_US';
import ar from 'ant-design-vue/es/date-picker/locale/ar_EG';
</script>

<script>
export default {
    name: 'DatePicker'
}
</script>

<style></style>