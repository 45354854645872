<template>
    <AModal class="dialog" v-model:visible="visible" :confirmLoading="loading" destroyOnClose :maskClosable="closeOnClick"
        v-bind="$attrs" :width="width">
        <slot></slot>
    </AModal>
</template>
<script>
export default {
    name: 'Dialog',
    inheritAttrs: false
}
</script>
<script setup>
import { ref } from 'vue';

defineProps({
    /**
     * 宽度
     */
    width: {
        type: [Number, String],
        default: 700
    },
    /**
     * 点击遮罩关闭
     */
    closeOnClick: {
        type: Boolean,
        default: false
    }
});

defineExpose({
    show() {
        visible.value = true;
    },
    hide() {
        visible.value = false;
    },
    start() {
        loading.value = true;
    },
    stop() {
        loading.value = false;
    }
});

/**
 * 显示隐藏
 */
const visible = ref(false);
/**
 * loading
 */
const loading = ref(false);
</script>

<style lang="scss">
.dialog {
    .ant-modal-body {
        overflow: auto;
        max-height: 70vh;
    }
}
</style>
