/**
 * 公共方法文件
 */

import { Modal } from 'ant-design-vue';
import l from '@/i18n';
import { QuestionOutlined } from '@ant-design/icons-vue';
/**
 * 获取对象URL
 */
const getObjectURL = file => (window.URL || window.webkitURL || window).createObjectURL(file);

/**
 * 询问
 */
export const confirm = (content, title, options) => {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            content,
            icon: <QuestionOutlined />,
            title: title || l.global.t('ask'),
            onOk(close) {
                close();
                resolve();
            },
            onCancel(close) {
                typeof close === 'function' && close();
                reject();
            },
            ...options
        })
    });
}

/**
 * 询问
 */
export const asyncConfirm = (content, title, options) => {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            content,
            icon: <QuestionOutlined />,
            title: title || l.global.t('ask'),
            onOk() {
                return new Promise(res => {
                    resolve(res);
                });
            },
            onCancel(close) {
                typeof close === 'function' && close();
                reject();
            },
            ...options
        })
    });
}

/**
 * 创建url
 */
export const createUrl = file => (!file || typeof file === 'string') ? file : getObjectURL(file);

/**
 * 日期格式化
 */
export const dateFormat = 'YYYY-MM-DD';

/**
* 支付方式
*/
export const paymentOptions = {
    1: 'paypall',
    2: 'stripe',
    3: 'receivePay',
    4: 'qiCard',
    5: 'balancePay',
    6: 'mixPay'
}

/**
 * 售后类型
 */
export const returnType = {
    /**
     * 仅退款
     */
    onlyReturn: 1,
    /**
     * 退货退款
     */
    returnGoodsMoney: 2
}

/**
 * 状态对应的值
 */
export const statusValues = {
    tradeCancel: 0,
    unPay: 1,
    unSend: 2,
    unReceive: 3,
    unAssem: 4,
    tradeDone: 5,
    maintaining: [6, 8],
    maintainUnsend: 7,
    appealling: [9, 10],
    appealPassed: 11,
    sendUnConfirm: 12,
    repaybacking: 13
};

/**
 * 值和状态对应
 */
export const valueStatus = Object.keys(statusValues).reduce((obj, next) => {
    const values = statusValues[next];
    Array.isArray(values) ? values.forEach(item => obj[item] = next) : obj[values] = next;
    return obj
}, {});