<template>
    <Space wrap>
        <div :style="{ width: typeof size === 'string' ? size : size + 'px', height: typeof size === 'string' ? size : size + 'px' }"
            class="pic" v-for="(item, index) in files" :key="index">
            <ASpin :spinning="!item.done">
                <img :src="item.file" alt="" height="100%">
            </ASpin>
            <CloseOutlined @click="del(index)" />
        </div>
        <label v-if="(files?.length ?? 0) < max"
            :style="{ width: typeof size === 'string' ? size : size + 'px', height: typeof size === 'string' ? size : size + 'px' }"
            class="upload">
            <input type="file" ref="fileRef" accept="image/*" @change="change" :multiple="max > 1">
            <PlusOutlined />
        </label>
    </Space>
</template>

<script setup>
import { PlusOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { createUrl } from '@/utils/common';
import { reactive, ref, watch } from 'vue';
import api from '@/api/user';

const emit = defineEmits(['update:value']);
const fileRef = ref();
const props = defineProps({
    max: {
        type: Number,
        default: 1
    },
    value: {
        type: [Array, String],
        default: () => []
    },
    size: {
        type: Number,
        default: 100
    }
});

/**
 * 文件
 */
const files = reactive(Array.isArray(props.value) ? [...props.value?.map(c => ({
    file: c,
    done: true
}))] : props.value ? [{
    file: props.value,
    done: true
}] : []);

watch(() => props.value, v => {
    files.splice(0, Infinity, ...v.map(c => ({
        file: c,
        done: true
    })));
}, { deep: true });

/**
 * 删除
 */
const del = index => {
    const v = [...props.value];
    v.splice(index, 1);
    emit('update:value', v);
}

/**
 * 上传文件
 */
const change = async e => {
    const fs = [...e.target.files];
    if (files.length + fs.length > props.max) {
        fs.length = props.max - files.length;
    }
    if (fs.length < 1) return;
    const _urls = fs.map(c => ({
        file: createUrl(c),
        done: false
    }));
    files.push(..._urls);
    try {
        const urls = await api.upload(fs);
        const start = files.findIndex(c => c.file === _urls[0].file);
        files.splice(start, fs.length, ...urls.map(c => ({
            file: c,
            done: true
        })));
    } catch (error) {
        const start = files.findIndex(c => c.file === _urls[0]);
        files.splice(start, fs.length);
    } finally {
        fileRef.value = '';
    }
    emit('update:value', files.filter(c => c.done).map(c => c.file));
}
</script>

<script>
export default {
    name: 'ImageUploader'
}
</script>

<style lang="scss" scoped>
.pic {
    position: relative;
    border: 1px solid #ccc;

    ::v-deep {
        & .ant-spin-nested-loading {
            height: 100%;
        }

        & .ant-spin-container {
            height: 100%;
        }

    }

    .ant-progress {
        position: absolute;
        left: 20px;
        z-index: 9;
        top: 20px;
    }

    .anticon {
        position: absolute;
        right: 0;
        top: 0;
        padding: 1px 6px;
        color: white;
        background: red;
        text-align: center;
        border-radius: 0 0 0 8px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.upload {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    border: 1px dashed #ccc;
    border-radius: 3px;
    transition: border-color .2s linear;

    input {
        display: none;
    }

    &:hover {
        border-color: rgb(22, 155, 213);
    }
}
</style>