/**
 * dayjs扩展
 */
export const dayjsExtends = (option, dayjsClass, dayjsFactory) => {
    dayjsClass.prototype.formatD = function () {
        return this.format('YYYY-MM-DD');
    }
    dayjsClass.prototype.toString = function () {
        return this.formatD();
    }
}