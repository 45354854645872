<template>
    <Space>
        <DatePicker :open="openA" @openChange="v => openA = v" :showToday="false" v-bind="$attrs" :value="value.startTime"
            @change="e => change(e, 0)">
            <template #footer>
                <Space flex center :size="20">
                    <a href="javascript:;;" v-t="'today'" @click="valueChange(0, 0)"></a>
                    <a href="javascript:;;" v-t="'near7Days'" @click="valueChange(7)"></a>
                    <a href="javascript:;;" v-t="'near30Days'" @click="valueChange(30)"></a>
                </Space>
            </template>
        </DatePicker>
        <span v-t="'to'"></span>
        <DatePicker :open="open" @openChange="v => open = v" :showToday="false" v-bind="$attrs" :value="value.endTime"
            @change="e => change(e, 1)">
            <template #footer>
                <Space flex center :size="20">
                    <a href="javascript:;;" v-t="'today'" @click="valueChange(0)"></a>
                    <a href="javascript:;;" v-t="'near7Days'" @click="valueChange(7)"></a>
                    <a href="javascript:;;" v-t="'near30Days'" @click="valueChange(30)"></a>
                </Space>
            </template>
        </DatePicker>
    </Space>
</template>

<script>
export default {
    name: 'DateRange'
}
</script>

<script setup>
import { Form } from 'ant-design-vue';
import { ref } from 'vue';
import dayjs from 'dayjs';

const props = defineProps({
    /**
     * 值
     */
    value: {
        type: Object,
        default: () => ({})
    }
});

const emit = defineEmits(['update:value']);

const { onFieldChange } = Form.useInjectFormItemContext();

/**
 * 是否打开日期面板
 */
const open = ref(false);
/**
 * 是否打开第一个日期面板
 */
const openA = ref(false);
/**
 * 值变更
 */
const change = (v, index = 0) => {
    emit('update:value', { ...props.value, [index === 0 ? 'startTime' : 'endTime']: v });
    onFieldChange();
}
/**
 * 值更改
 */
const valueChange = (value, index = 1) => {
    const v = { ...props.value };
    if (value === 0) {
        v[index === 0 ? 'startTime' : 'endTime'] = dayjs();
    } else {
        v.endTime = dayjs();
        v.startTime = dayjs().add(-value, 'days');
    }
    emit('update:value', v);
    onFieldChange();
    open.value = false;
    openA.value = false;
}
</script>

<style lang="scss" scoped></style>