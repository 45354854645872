<template>
    <Space flex>
        <AFormItemRest>
            <ASelect @change="selectChange" :value="value.areaCode" style="width:150px"
                :options="areaCodes" />
        </AFormItemRest>
        <AInput :maxlength="15" :value="value.shopTel" @change="change" />
    </Space>
</template>

<script>
export default {
    name: 'Phone'
}
</script>
<script setup>
import { Form } from 'ant-design-vue';
import api from '@/api/shop';
import { ref } from 'vue';
const { onFieldChange } = Form.useInjectFormItemContext();

const props = defineProps({
    value: {
        type: Object,
        default: () => ({
            areaCode: '+86',
            shopTel: ''
        })
    }
});

const emit = defineEmits(['update:value']);

/**
 * select组件更改事件
 */
const selectChange = value => {
    emit('update:value', {
        shopTel: props.value.shopTel,
        areaCode: value
    });
    onFieldChange();
}

/**
 * 值更改
 */
const change = e => {
    const { value } = e.target;
    if (!value || /^\d+$/.test(value)) {
        emit('update:value', {
            areaCode: props.value.areaCode,
            shopTel: value
        });
        onFieldChange();
    }
}

// 区号
/**
 * 区号列表
 */
const areaCodes = ref([]);
/**
 * 获取区号
 */
const getAreaCode = async () => {
    try {
        const list = await api.areaCode();
        areaCodes.value = list.map(({ mobilePrefix }) => ({
            label: mobilePrefix,
            value: mobilePrefix
        }));
    } catch (error) { }
}

getAreaCode();

</script>