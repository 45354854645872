/**
 * 商品接口
 */

import request from './request';

export default {
    /**
     * 优惠券列表
     */
    discountList: model => request.post('discountList', model, { no: true }),
    /**
     * 添加优惠券
     */
    addDiscount: model => request.post('addDiscount', model),
    /**
     * 修改优惠券
     */
    editDiscount: model => request.post('editDiscount', model),
    /**
     * 删除优惠券
     */
    removeDiscount: discountId => request.post('removeDiscount', { discountId }),
    /**
     * 下架优惠券
     */
    offLineDiscount: model => request.post('offLineDiscount', model),
    /**
     * 上架优惠券
     */
    onLineDiscount: model => request.post('onLineDiscount', model),
    /**
     * 根据店铺商品分类查询商品
     */
    commodityListByShopCategory: shopCategoryId => request.get('shop/commodity/category/commodity/list', { shopCategoryId, no: true }),
    /**
     * 批量将多个商品设置成商品分类
     */
    batchCommodityShopCategory: model => request.post('batchCommodityShopCategory', model),
    /**
     * 店铺商品列表
     */
    commodityList: model => request.post('shop/commodityList', model, { no: true }),
    /**
     * 将商品移除分类
     */
    removeCommodityShopCategory: model => request.post('shop/removeCommodityShopCategory', model),
    /**
     * 查询优惠券关联的商品
     */
    discountCommodity: discountId => request.post('discountCommodity', { discountId }, { no: true }),
    /**
     * 订单列表
     */
    list: params => request.get('order/all/list', { params, no: true }),
    /**
     * 批量设置分类
     */
    batchSetCategory: model => request.put('shop/commodity/category/commodity/batch', model),
    /**
     * 移除分类
     */
    removeClass: id => request.delete('shop/commodity/category/commodity/' + id),
    /**
     * 活动列表
     */
    activiyList: model => request.post('activiyList', model, { no: true }),
    /**
     * 活动详情
     */
    activeDetails: activityId => request.post('activeDetails', { activityId }, { no: true }),
    /**
     * 添加活动
     */
    addActive: model => request.post('addActive', model),
    /**
     * 更新活动
     */
    editActive: model => request.post('editActive', model),
    /**
     * 订单收入明细
     */
    orderRecord: model => request.post('orderRecord', model, { no: true }),
    /**
     * 查询店铺信息
     */
    shopInfo: () => request.post('shopInfo', {}, { no: true }),
    /**
     * 提现记录
     */
    cashOutRecord: model => request.post('cashOutRecord', model, { no: true, noHandle: true }),
    /**
     * 其他配置
     */
    otherShopConfig: () => request.post('otherShopConfig', {}, { no: true }),
    /**
     * 添加提现记录
     */
    addCashOut: model => request.post('addCashOut', model, { no: true }),
    /**
     * 查询基本信息
     */
    getBusinessInfo: () => request.post('getBusinessInfo', {}, { no: true }),
    /**
     * 重置管理员密码
     */
    resetAdminPassword: model => request.post('resetAdminPassword', model),
    /**
     * 修改基础信息
     */
    commitBusinessInfo: model => request.post('commitBusinessInfo', model),
    /**
     * 保证金状态查询
     */
    cashProStatus: () => request.post('cashProStatus', {}, { no: true }),
    /**
     * 保证金记录
     */
    cashProRecord: model => request.post('cashProRecord', model, { no: true }),
    /**
     * 保证金缴纳/申请退款
     */
    cashProOperation: model => request.post('cashProOperation', model),
    /**
     * 更新其他配置
     */
    updateOtherShopConfig: model => request.post('updateOtherShopConfig', model),
    /**
     * 删除活动
     */
    removeActive: activityId => request.post('removeActive', { activityId }),
    /**
     * 活动上下架
     */
    activeStatus: model => request.post('activeStatus', model),
    /**
     * 提现手续费
     */
    cashOutRate: () => request.post('cashOutRate', {}, { no: true }),
    /**
     * 统计详情
     */
    generalize: params => request.get('operation/generalize/details', { params, no: true }),
    /**
     * 获取是否有未读消息
     */
    unread: () => request.get('message/user/unread', { no: true }),
    /**
     * 获取商品分类
     */
    shopCategoryList: showType => request.get('shop/commodity/category/allList', { headers: { showType }, no: true }),
    /**
     * 获取区号
     */
    areaCode: () => request.get('areaCode', { no: true })
}