<template>
    <ASpin :spinning="loading">
        <ul :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }">
            <li>
                <p v-t="'allSalesMoney'"></p>
                <p>
                    <a-statistic :value="data.sumMoney" />
                </p>
                <p v-t="'perDaySalesMoney'"></p>
                <p>
                    <a-statistic :value="data.dayMoney" />
                </p>
            </li>
            <li>
                <p><span>{{ $t('orderCount') }}</span><a-statistic :value="data.allSize" /></p>
                <p><span>{{ $t('perDayOrderCount') }}</span><a-statistic :value="data.daySize" /></p>
                <p><span>{{ $t('unSend') }}</span><a-statistic :value="data.unfilledSize" /></p>
                <p><span>{{ $t('willRepay') }}</span><a-statistic :value="data.refundSize" /></p>
            </li>
            <li>
                <p v-t="'partyCount'"></p>
                <p>
                    <a-statistic :value="data.activityTotal" />
                </p>
                <p><span>{{ $t('going') }}</span><a-statistic :value="data.activityUnderway" /></p>
                <p><span>{{ $t('notStart') }}</span><a-statistic :value="data.activityUnplayed" /></p>
            </li>
            <li>
                <p v-t="'couponCount'"></p>
                <p>
                    <a-statistic :value="data.discountNum" />
                </p>
                <p><span>{{ $t('gotCount') }}</span><a-statistic :value="data.discountGetNum" /></p>
                <p><span>{{ $t('used') }}</span><a-statistic :value="data.discountUseNum" /></p>
            </li>
            <li>
                <p v-t="'goodsCount'"></p>
                <p>
                    <a-statistic :value="data.goodNum" />
                </p>
                <p v-t="'skuWarn'"></p>
                <p>
                    <a-statistic :value="data.warningNum" />
                </p>
            </li>
        </ul>
        <div class="charts">
            <nav>
                <span v-t="'salesMoney'"></span>
                <Space>
                    <a href="javascript:;;" v-t="'today'" @click="changeDate(1)"></a>
                    <a href="javascript:;;" v-t="'thisWeek'" @click="changeDate(2)"></a>
                    <a href="javascript:;;" v-t="'thisMonth'" @click="changeDate(3)"></a>
                    <a href="javascript:;;" v-t="'fullYear'" @click="changeDate(4)"></a>
                    <ARangePicker v-model:value="date" :disabledDate="disabledDate" @calendarChange="onCalendarChange"
                        @change="getData()" />
                </Space>
            </nav>
            <div class="eCharts" ref="charts"></div>
        </div>
    </ASpin>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";
import * as echarts from 'echarts';
import api from '@/api/shop';

/**
 * 语言国际化对象
 */
const t = inject('t');
/**
 * 语言对象
 */
const l = inject('l');
/**
 * charts引用
 */
const charts = ref();
/**
 * 日期
 */
const date = ref();
/**
 * 当前日期
 */
const dates = ref();
/**
 * 数据
 */
const data = ref({});
/**
 * loading
 */
const loading = ref(false);
/**
 * chart对象
 */
let myChart = null;

/**
 * 更换日期
 */
const changeDate = type => {
    date.value = undefined;
    getData(type);
}

/**
 * 获取数据
 */
const getData = async type => {
    try {
        loading.value = true;
        const [start, end] = date.value || [];
        data.value = await api.generalize({
            type,
            start,
            end
        });
        draw();
    } catch (error) { }
    loading.value = false;
}

/**
 * 绘制
 */
const draw = () => {
    !myChart && (myChart = echarts.init(charts.value));
    const list = [];
    if (data.value?.list) {
        Object.entries(data.value?.list).map(([name, value]) => {
            list.push({
                name,
                value
            });
        });
    }
    myChart.setOption({
        title: {
            text: t('salesMoneyTrend')
        },
        tooltip: {},
        xAxis: {
            data: list.map(c => c.name)
        },
        yAxis: {},
        series: [
            {
                name: t('sellCount'),
                type: 'bar',
                data: list.map(c => c.value)
            }
        ]
    });
}

onMounted(() => {
    getData(3);
});

/**
 * 不可选的日期
 */
const disabledDate = current => {
    if (!dates.value?.length) {
        return false;
    }
    const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
    const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
    return tooEarly || tooLate;
}

/**
 * 日历打开
 */
const onCalendarChange = value => {
    dates.value = value;
}
</script>

<style lang="scss" scoped>
ul {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    li {
        flex: 1;
        padding: 10px 20px;
        height: 200px;
        background: white;

        p {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
        }
    }
}

.charts {
    margin-top: 30px;
    background: white;

    .eCharts {
        height: 500px;
        margin: 30px 20px 0;
    }

    nav {
        display: flex;
        align-items: center;
        padding: 5px 30px;
        justify-content: space-between;

        &>span {
            color: #1890ff;
            font-size: 16px;
            position: relative;

            &::before {
                content: '';
                display: inline-block;
                height: 2px;
                width: calc(100% + 30px);
                background: #0c88fd;
                position: absolute;
                bottom: -12px;
                left: 0;
                transform: translateX(-15px);
            }
        }
    }
}
</style>