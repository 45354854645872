import zh from './zh';
import en from './en';
import ar from './ar';
import { createI18n } from 'vue-i18n';
import 'ant-design-vue/dist/antd.css';
import { languageKey } from '@/utils/consts';
import { i18nChangeLanguage } from '@wangeditor/editor'
const language = localStorage.getItem(languageKey) || 'zh';

/**
 * i18n对象
 */
const i18n = createI18n({
    globalInjection: true, //全局生效$t
    locale: language, // 设置地区
    messages: {
        zh,
        en,
        ar
    }, // 设置地区信息
    legacy: false,
});

const keys = {
    zh: 'zh-CN',
    en: 'en',
    ar: 'ar'
};
i18nChangeLanguage(keys[language]);

export default i18n;