/**
 * 组件注册文件
 */

import Form from './Form.vue';
import Table from './Table.vue';
import Dialog from './Dialog.vue';
import DF from './DF.vue';
import Space from './Space.vue';
import DateRange from './DateRange';
import MyDatePicker from './DatePicker.vue';
import Phone from './Phone';
import SmallImageUploader from './SmallImageUploader';
import Address from './Address';
import CardSelect from './CardSelect';
import GoodsSelector from './GoodsSelector';
import GoodsSelectorHasPrice from './GoodsSelectorHasPrice';
import ImageUploader from './ImageUploader';
import Price from './Price';
import { Button, TreeSelect, Modal, Empty, AutoComplete, Spin, Select, Breadcrumb, Image, Pagination, Rate, Steps, Progress, Upload, Divider, Menu, Badge, Tabs, ConfigProvider, Statistic, Form as AForm, Radio, InputNumber, Cascader, Input, DatePicker, Tree, Checkbox } from 'ant-design-vue';

/**
 * 注册组件
 * @param app 
 */
const register = app => {
    [Button, Modal, Select, Spin, TreeSelect, Empty, AutoComplete, Breadcrumb, Menu, Divider, Pagination, Image, Rate, Steps, Upload, Progress, ConfigProvider, Badge, Statistic, Tabs, AForm, Radio, InputNumber, Cascader, Input, DatePicker, Tree, Checkbox].forEach(item => app.use(item));
    [Form, Space, Phone, Address, GoodsSelectorHasPrice, Price, ImageUploader, GoodsSelector, CardSelect, SmallImageUploader, Table, Dialog, MyDatePicker, DF, DateRange].forEach(item => {
        app.component(item.name, item);
    });
}

export default register;