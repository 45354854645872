<template>
    <div style="flex:1">
        <Space flex>
            <ASelect :options="areas[0]" :value="value.shopAreaCode[0]" @change="change($event, 0)"
                :placeholder="$t('choseCountryPh')" />
            <ASelect :options="areas[1]" :value="value.shopAreaCode[1]" @change="change($event, 1)"
                :placeholder="$t('choseProvincePh')" />
            <ASelect :options="areas[2]" :value="value.shopAreaCode[2]" @change="change($event, 2)"
                :placeholder="$t('choseCityPh')" />
            <ASelect :options="areas[3]" :value="value.shopAreaCode[3]" @change="change($event, 3)"
                :placeholder="$t('choseDistrictPh')" />
        </Space>
        <AInput class="input" :value="value.shopDetailAddress" @change="e => change(e.target.value)" :maxlength="200" />
    </div>
</template>

<script setup>
import { Form } from 'ant-design-vue';
import api from '@/api/user';
import { ref } from 'vue';

const props = defineProps({
    value: {
        type: Object,
        default: () => ({
            shopAreaCode: []
        })
    }
});

const emit = defineEmits(['update:value']);
const { onFieldChange } = Form.useInjectFormItemContext();
const areas = ref([
    [], [], [], []
]);

/**
 * 获取区域
 */
const getAreas = async (pid, index = -1) => {
    try {
        const list = await api.areaList(pid);
        areas.value[index + 1].splice(0, Infinity, ...list.map(c => ({ label: c.name, value: c.id })));
    } catch (error) { }
}

getAreas();

props.value.shopAreaCode?.forEach((pid, index) => {
    index < 3 && pid && getAreas(pid, index);
});

/**
 * 值改变
 */
const change = (value, index) => {
    const obj = { ...props.value };
    if (index === undefined) {
        obj.shopDetailAddress = value;
    } else {
        obj.shopAreaCode[index] = value;
    }
    areas.value.forEach((item, idx) => idx > index && item.splice(0, Infinity));
    obj.shopAreaCode?.forEach((item, idx) => {
        idx > index && (obj.shopAreaCode[idx] = undefined);
    });
    index < 3 && getAreas(value, index);
    emit('update:value', obj);
    onFieldChange();
}
</script>

<script>
export default {
    name: 'Address',
    inheritAttrs: false
}
</script>

<style lang="scss" scoped>
.input {
    margin-top: 10px;
}
</style>