<template>
    <Dialog @ok="ok" v-bind="$attrs" ref="dialogRef">
        <Form v-bind="$attrs" ref="formRef" @finish="$emit('ok', $event)"></Form>
    </Dialog>
</template>

<script>
export default {
    name: 'DF'
}
</script>
<script setup>
import { ref } from 'vue';

/**
 * dialog引用
 */
const dialogRef = ref();
/**
 * form引用
 */
const formRef = ref();

const emit = defineEmits(['ok']);

/**
 * OK事件
 */
const ok = async () => {
    try {
        const model = await formRef.value.valid();
        dialogRef.value.start();
        emit('ok', model);
    } catch (error) {
        console.log(error);
    }
}

defineExpose({
    show: () => dialogRef.value.show(),
    hide: () => {
        dialogRef.value.stop();
        dialogRef.value.hide();
    },
    start: () => dialogRef.value.start(),
    stop: () => dialogRef.value.stop()
});
</script>

<style></style>