<template>
    <Space wrap>
        <label @click="change(item.value)" v-for="item in options" :key="item.value"
            :class="{ active: value.includes(item.value) }">{{ item.label
            }}</label>
    </Space>
</template>

<script setup>
import { Form } from 'ant-design-vue';

const { onFieldChange } = Form.useInjectFormItemContext();
const emit = defineEmits(['update:value']);
const props = defineProps({
    /**
     * 配置项
     */
    options: {
        type: Array,
        default: () => []
    },
    /**
     * 值
     */
    value: {
        type: Array,
        default: () => []
    }
});

/**
 * 更改时间
 */
const change = v => {
    const arr = [...props.value];
    const index = arr.findIndex(c => c == v);
    if (index >= 0) {
        arr.splice(index, 1);
    } else {
        arr.push(v);
    }
    emit('update:value', arr);
    onFieldChange();
}
</script>

<script>
/**
 * 卡片选择器
 */
export default {
    name: 'CardSelect'
}
</script>

<style lang="scss" scoped>
label {
    min-width: 100px;
    cursor: pointer;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    text-align: center;

    &.active {
        border-color: rgb(2, 167, 240);
        color: rgb(2, 167, 240);
    }
}
</style>