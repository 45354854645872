<template>
    <h1 v-t="'title'"></h1>
    <div class="box">
        <Form ref="formRef" space lw="100px" :loading="loading" :options="options" :model="user" :submit="$t('login')" reset
            @finish="login" />
    </div>
</template>

<script setup>
import { computed, inject, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import api from '@/api/user';
const t = inject('t');

/**
 * router对象
 */
const router = useRouter();
/**
 * 用户对象
 */
const user = reactive({});
/**
 * loading
 */
const loading = ref(false);
/**
 * 发送中
 */
const sending = ref(false);
/**
 * form引用
 */
const formRef = ref();
/**
 * 倒计时
 */
const count = ref(0);
/**
 * 表单配置项
 */
const options = computed(() => ({
    keyAccount: {
        title: t('phoneOrEmail'),
        required: true,
        max: 50
    },
    message: {
        title: t('captcha'),
        required: true,
        max: 6,
        after: count.value > 0 ? t('captchaPh').replace('[s]', count.value) : sending.value ? <a disabled href="javascript:;;"> {t('getCaptcha')}</a> : <a href="javascript:;;" onClick={getCaptcha}> {t('getCaptcha')}</a>
    },
    password: {
        title: t('newPassword'),
        required: true,
        max: 50,
    },
    confimPassword: {
        title: t('confirmPassword'),
        required: true,
        max: 50,
        rules: [{
            validator(rule, value) {
                return user.password !== value ? Promise.reject(t('passNoMatch')) : Promise.resolve();
            }
        }]
    }
}));

/**
 * 获取验证码
 */
const getCaptcha = async () => {
    try {
        const obj = await formRef.value.validFields(['keyAccount']);
        sending.value = true;
        await api.getMessage(obj);
        count.value = 60;
        const id = setInterval(() => {
            if (--count.value === 0) {
                clearInterval(id);
            }
        }, 1000);
    } catch (error) { }
    sending.value = false;
}

/**
 * 登录
 */
const login = async values => {
    try {
        loading.value = true;
        await api.resetPassword(values);
        router.replace({ name: 'login' });
    } catch (error) { }
    loading.value = false;
}
</script>

<style lang="scss" scoped>
h1 {
    margin: 0;
    padding-top: 100px;
    font-weight: bold;
    text-align: center;
}

.box {
    margin: 50px auto 0;
    width: 700px;
    border-radius: 5px;
    padding: 50px;
    border: 1px solid #ccc;
}
</style>