import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import MainLayout from '@/layouts/MainLayout';
import Summary from '@/views/Summary';
import Login from '@/views/Account/Login';
import Forgot from '@/views/Account/Forgot';

const routes = [
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  {
    name: 'forgot',
    path: '/forgot',
    meta: { unAuth: true },
    component: Forgot
  },
  {
    path: '/',
    name: 'layout',
    component: MainLayout,
    redirect: { name: 'summary' },
    children: [
      {
        name: 'summary',
        path: '/summary',
        meta: { nobg: true },
        component: Summary
      },
      {
        name: 'roles',
        path: '/rights/roles',
        meta: { routes: ['rightsManage', 'rolesManage'] },
        component: () => import('@/views/Rights/Roles')
      },
      {
        name: 'admin',
        path: '/rights/admin',
        meta: { routes: ['rightsManage', 'adminManage'] },
        component: () => import('@/views/Rights/Admin')
      },
      {
        name: 'messages',
        path: '/messages',
        component: () => import('@/views/Messages')
      },
      {
        name: 'baseinfo',
        path: '/seller/baseinfo',
        component: () => import('@/views/Settings/BaseInfo')
      },
      {
        name: 'editBaseInfo',
        path: '/seller/baseinfo/edit',
        component: () => import('@/views/Settings/BaseInfo/edit')
      },
      {
        name: 'resetpassword',
        path: '/seller/baseinfo/resetpassword',
        component: () => import('@/views/Settings/ResetPassword')
      },
      {
        name: 'othersettings',
        path: '/seller/othersettings',
        component: () => import('@/views/Settings/Other')
      },
      {
        name: 'editOtherSettings',
        path: '/seller/othersettings/edit',
        component: () => import('@/views/Settings/Other/edit')
      },
      {
        name: 'bond',
        path: '/seller/bond',
        component: () => import('@/views/Settings/Bond')
      },
      {
        name: 'bondLog',
        path: '/seller/bond/log',
        component: () => import('@/views/Settings/Bond/log')
      },
      {
        name: 'coupon',
        path: '/run/coupon',
        meta: { routes: ['runManage', 'couponManage'] },
        component: () => import('@/views/Run/Coupon')
      },
      {
        name: 'party',
        path: '/run/party',
        meta: { routes: ['runManage', 'partyManage'] },
        component: () => import('@/views/Run/Party')
      },
      {
        name: 'orderDetailA',
        path: '/finance/order',
        meta: { routes: ['financeManage', 'orderDetailA'] },
        component: () => import('@/views/Finance/OrderDetail')
      },
      {
        name: 'withdrawLog',
        path: '/finance/withdraw',
        meta: { routes: ['financeManage', 'withdrawLog'] },
        component: () => import('@/views/Finance/WithdrawLog')
      },
      {
        name: 'goodsClassify',
        path: '/goods/classify',
        meta: { routes: ['goodsManage', 'goodsClassify'] },
        component: () => import('@/views/Goods/Classify')
      },
      {
        name: 'relationGoods',
        path: '/goods/relation/:id',
        props: true,
        meta: { routes: ['goodsManage', 'goodsClassify', 'relationGoods'] },
        component: () => import('@/views/Goods/Classify/relation')
      },
      {
        name: 'selling',
        path: '/goods/selling',
        meta: { routes: ['goodsManage', 'selling'] },
        component: () => import('@/views/Goods/Selling')
      },
      {
        name: 'auditing',
        path: '/goods/auditing',
        meta: { routes: ['goodsManage', 'auditing'] },
        component: () => import('@/views/Goods/Auditing')
      },
      {
        name: 'repository',
        path: '/goods/repository',
        meta: { routes: ['goodsManage', 'repository'] },
        component: () => import('@/views/Goods/Repository')
      },
      {
        name: 'publish',
        path: '/goods/selling/publish/:id?/:type?',
        props: true,
        meta: { routes: ['goodsManage', 'selling', 'publishGoods'] },
        component: () => import('@/views/Goods/Selling/publish')
      },
      {
        name: 'comment',
        path: '/order/comment',
        meta: { routes: ['orderManage', 'commentManage'] },
        component: () => import('@/views/Order/Comment')
      },
      {
        name: 'allOrder',
        path: '/order/all',
        meta: { routes: ['orderManage', 'allOrder'] },
        component: () => import('@/views/Order/All')
      },
      {
        name: 'orderDetail',
        path: '/order/detail/:id?',
        props: true,
        meta: { routes: ['orderManage', 'orderDetail'] },
        component: () => import('@/views/Order/Detail')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(({ name, meta }, from, next) => {
  const { token } = store.getters;
  /**
   * 如果无需校验
   */
  if (meta.unAuth) {
    return next();
  }
  if (name === 'login' && token) {
    return next({ name: 'summary' });
  }
  if (name !== 'login' && !token) {
    return next({ name: 'login' });
  }
  next();
});

export default router
