<template>
    <header :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }">
        <div class="language">
            <label v-t="'language'"></label>
            <ASelect style="width:130px" :value="$i18n.locale" @change="changeLanguage"
                :options="['ar', 'zh', 'en'].map(item => ({ label: $t(item), value: item }))"></ASelect>
        </div>
    </header>
    <h1 v-t="'title'"></h1>
    <div class="box">
        <Form space :loading="loading" :options="options" :model="user" :submit="$t('login')" reset @finish="login" />
        <Space flex style="padding: 0 80px;">
            <router-link v-t="'forgotPassword'" :to="{ name: 'forgot' }"></router-link>
        </Space>
    </div>
</template>

<script setup>
import { languageKey } from '@/utils/consts';
import { computed, inject, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import api from '@/api/user';

/**
 * 语言国际化对象
 */
const t = inject('t');
/**
 * router对象
 */
const router = useRouter();
/**
 * vuex对象
 */
const store = useStore();
/**
 * 用户对象
 */
const user = reactive({});
/**
 * loading
 */
const loading = ref(false);
/**
 * 表单配置项
 */
const options = computed(() => ({
    account: {
        title: t('username'),
        required: true,
        max: 50
    },
    shopPassword: {
        title: t('password'),
        required: true,
        max: 50,
        type: 'password'
    }
}));

/**
 * 改变语言
 */
const changeLanguage = v => {
    window.localStorage.setItem(languageKey, v);
    window.location.reload();
}

/**
 * 登录
 */
const login = async values => {
    try {
        loading.value = true;
        const user = await api.userLogin(values);
        store.commit('setUser', user);
        store.dispatch('getOtherConfig');
        router.replace({ name: 'summary' });
    } catch (error) { console.log(error); }
    loading.value = false;
}
</script>

<style lang="scss" scoped>
h1 {
    margin: 0;
    padding-top: 100px;
    font-weight: bold;
    text-align: center;
}

.box {
    margin: 50px auto 0;
    width: 700px;
    border-radius: 5px;
    padding: 50px;
    border: 1px solid #ccc;
}

header {
    height: 50px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 100px;
    font-size: 16px;
    gap: 70px;
    flex-shrink: 0;

    .language {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}
</style>