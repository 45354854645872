<template>
    <div class="layout-wrapper" :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }">
        <aside>
            <header v-t="'shopManager'"></header>
            <AMenu mode="inline" theme="dark">
                <AMenuItem key="sub0">
                    <template #icon>
                        <HomeFilled />
                    </template>
                    <RouterLink :to="{ name: 'summary' }" v-t="'statistics'"></RouterLink>
                </AMenuItem>
                <ASubMenu key="sub6">
                    <template #icon>
                        <EditOutlined />
                    </template>
                    <template #title>{{ $t('goodsManage') }}</template>
                    <AMenuItem key="21">
                        <RouterLink :to="{ name: 'selling' }" v-t="'selling'"></RouterLink>
                    </AMenuItem>
                    <AMenuItem key="25">
                        <RouterLink :to="{ name: 'auditing' }" v-t="'auditing'"></RouterLink>
                    </AMenuItem>
                    <AMenuItem key="26">
                        <RouterLink :to="{ name: 'repository' }" v-t="'repository'"></RouterLink>
                    </AMenuItem>
                    <AMenuItem key="20">
                        <RouterLink :to="{ name: 'goodsClassify' }" v-t="'goodsClassify'"></RouterLink>
                    </AMenuItem>
                </ASubMenu>
                <ASubMenu key="sub7">
                    <template #icon>
                        <EditOutlined />
                    </template>
                    <template #title>{{ $t('orderManage') }}</template>
                    <AMenuItem key="22">
                        <RouterLink :to="{ name: 'allOrder' }" v-t="'allOrder'"></RouterLink>
                    </AMenuItem>
                    <AMenuItem key="23">
                        <RouterLink :to="{ name: 'comment' }" v-t="'commentManage'"></RouterLink>
                    </AMenuItem>
                </ASubMenu>
                <ASubMenu key="sub1">
                    <template #icon>
                        <EditOutlined />
                    </template>
                    <template #title>{{ $t('rightsManage') }}</template>
                    <AMenuItem key="5">
                        <RouterLink :to="{ name: 'roles' }" v-t="'rolesManage'"></RouterLink>
                    </AMenuItem>
                    <AMenuItem key="6">
                        <RouterLink :to="{ name: 'admin' }" v-t="'adminManage'"></RouterLink>
                    </AMenuItem>
                </ASubMenu>
                <ASubMenu key="sub3">
                    <template #icon>
                        <EditOutlined />
                    </template>
                    <template #title>{{ $t('runManage') }}</template>
                    <AMenuItem key="13">
                        <RouterLink :to="{ name: 'coupon' }" v-t="'couponManage'"></RouterLink>
                    </AMenuItem>
                    <AMenuItem key="14">
                        <RouterLink :to="{ name: 'party' }" v-t="'partyManage'"></RouterLink>
                    </AMenuItem>
                </ASubMenu>
                <ASubMenu key="sub5">
                    <template #icon>
                        <EditOutlined />
                    </template>
                    <template #title>{{ $t('financeManage') }}</template>
                    <AMenuItem key="15">
                        <RouterLink :to="{ name: 'orderDetailA' }" v-t="'orderDetailA'"></RouterLink>
                    </AMenuItem>
                    <AMenuItem key="16">
                        <RouterLink :to="{ name: 'withdrawLog' }" v-t="'withdrawLog'"></RouterLink>
                    </AMenuItem>
                </ASubMenu>
                <ASubMenu key="sub2">
                    <template #icon>
                        <EditOutlined />
                    </template>
                    <template #title>{{ $t('shopSettings') }}</template>
                    <AMenuItem key="9">
                        <RouterLink :to="{ name: 'baseinfo' }" v-t="'baseInfo'"></RouterLink>
                    </AMenuItem>
                    <AMenuItem key="11">
                        <RouterLink :to="{ name: 'bond' }" v-t="'bond'"></RouterLink>
                    </AMenuItem>
                    <AMenuItem key="10">
                        <RouterLink :to="{ name: 'othersettings' }" v-t="'otherSettings'"></RouterLink>
                    </AMenuItem>
                </ASubMenu>
            </AMenu>
        </aside>
        <section>
            <header :style="{ direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr' }">
                <RouterLink :to="{ name: 'messages' }">
                    <ABadge :dot="hasNew > 0" :offset="[-5, 3]">
                        <BellFilled :style="{ fontSize: '25px' }" />
                    </ABadge>
                </RouterLink>
                <span>{{ $store.state.user.shopInfo.shopName }}</span>
                <div class="language">
                    <label v-t="'language'"></label>
                    <ASelect style="width:130px" :value="$i18n.locale" @change="changeLanguage"
                        :options="['ar', 'zh', 'en'].map(item => ({ label: $t(item), value: item }))"></ASelect>
                </div>
                <a href="javascript:;;" v-t="'exit'" @click="exit"></a>
            </header>
            <main>
                <ABreadcrumb class="nav" v-if="$route.meta?.routes">
                    <ABreadcrumbItem v-for="code in $route.meta.routes" :key="code">{{ $t(code) }}</ABreadcrumbItem>
                </ABreadcrumb>
                <div class="content" :nobg="$route.meta.nobg">
                    <RouterView />
                </div>
            </main>
        </section>
    </div>
</template>

<script setup>
import { languageKey } from '@/utils/consts';
import { getCurrentInstance, ref } from 'vue';
import { confirm } from '@/utils/common';
import { useStore } from 'vuex';
import { BellFilled, HomeFilled, EditOutlined } from '@ant-design/icons-vue';
import api from '@/api/shop';
const { appContext: { config: { globalProperties: g } } } = getCurrentInstance();

/**
 * vuex对象
 */
const store = useStore();
/**
 * 是否有新消息
 */
const hasNew = ref(0);

/**
 * 改变语言
 */
const changeLanguage = v => {
    window.localStorage.setItem(languageKey, v);
    window.location.reload();
}

/**
 * 退出
 */
const exit = async () => {
    try {
        await confirm(g.$t('askExit'));
        store.dispatch('exit');
    } catch (error) { }
}

/**
 * 获取新消息
 */
const getNewMessage = async () => {
    try {
        hasNew.value = await api.unread();
    } catch (error) { }
}

getNewMessage();
</script>

<style lang="scss" scoped>
.layout-wrapper {
    display: flex;
    height: 100%;

    aside {
        width: 220px;
        background: rgba(0, 21, 41, 1);

        header {
            height: 50px;
            background: rgba(0, 40, 77, 1);
            font-size: 20px;
            line-height: 50px;
            text-align: center;
            font-weight: bold;
            color: white;
        }
    }

    section {
        flex: 1;
        background: rgb(240, 242, 245);
        display: flex;
        flex-direction: column;

        header {
            height: 50px;
            background: white;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 100px;
            font-size: 16px;
            gap: 70px;
            flex-shrink: 0;

            .language {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        main {
            flex: 1;
            padding: 30px;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .nav {
                margin: -20px 0 10px;
            }

            .content {
                flex: 1;
                overflow: auto;
                background: white;
                border-radius: 5px;
                padding: 15px;

                &[nobg] {
                    background: transparent;
                }
            }
        }
    }
}
</style>