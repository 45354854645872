<template>
    <label :style="{ color, fontSize: size + 'px' }">{{ symbol ? symbol + (price ?? '') : $t('moneySign').replace('[m]',
        null !== price && undefined !== price ? price : '') }}</label>
</template>

<script setup>
import { getCurrentInstance } from 'vue';
defineProps({
    price: [String, Number],
    Size: Number,
    color: String,
    symbol: String
});
const { appContext: { config: { globalProperties: { $i18n: l } } } } = getCurrentInstance();
const status = {
    zh: 'chineseMoneyName',
    en: 'ukMoneyName',
    ar: 'arabMoneyName'
};
</script>
<script>
export default {
    name: 'Price'
}
</script>

<style lang="scss" scoped>
label {
    color: #D9001B;
    font-size: 25px;
}
</style>