<template>
    <div>
        <ATable :size="size" :pagination="false" bordered :dataSource="data" :columns="columns" rowKey="id" v-bind="$attrs">
            <template #bodyCell="{ column, text, index, record }">
                <slot :name="column.dataIndex" :text="text" :index="index" :row="record" :column="column"
                    v-if="$slots[column.dataIndex]" />
            </template>
        </ATable>
        <Space flex class="pagination" v-if="$slots.footer || page">
            <slot name="footer"></slot>
            <APagination v-if="page" v-model:current="page.pageNum" size="middle" v-model:pageSize="page.pageSize"
                :total="page.total" :show-total="total => $t('total').replace('[total]', total)"
                @change="(page, size) => $emit('pageChange', page, size)" />
        </Space>
    </div>
</template>

<script>
export default {
    name: 'Table'
}
</script>

<script setup>
import { Table as ATable } from 'ant-design-vue';
import { computed } from 'vue';

const props = defineProps({
    /**
     * 数据
     */
    data: {
        type: Array,
        required: true
    },
    /**
     * 分页对象
     */
    page: Object,
    /**
     * 大小
     */
    size: {
        type: String,
        default: 'middle'
    },
    /**
     * 列
     */
    cols: {
        type: Object,
        required: true
    }
});

/**
 * 列
 */
const columns = computed(() => {
    const _cols = [];
    for (const key in props.cols) {
        const item = props.cols[key];
        if (typeof item === 'string') {
            _cols.push({
                dataIndex: key,
                align: 'center',
                title: item
            });
        } else {
            _cols.push({
                dataIndex: key,
                align: 'center',
                ...item
            });
        }
    }
    return _cols;
});
</script>

<style lang="scss" scoped>
// 表格
.ant-table-wrapper {
    margin-top: 15px;
}

// 分页
.pagination {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    :deep .ant-select-selector {
        height: 32px !important;

        .ant-select-selection-item {
            line-height: 32px !important;
        }
    }
}
</style>