/**
 * 商品接口
 */

import request from './request';

export default {
    /**
     * 添加商品
     */
    addGoods: model => request.post('shop/commodity/on/sale', model),
    /**
     * 删除商品
     */
    deleteGoods: id => request.delete('shop/commodity/on/sale/' + id),
    /**
     * 出售中列表
     */
    saleList: params => request.get('shop/commodity/on/sale/list', {
        params,
        no: true
    }),
    /**
     * 添加商品分类
     */
    shopCategoryAdd: model => request.post('shop/commodity/category', model),
    /**
     * 修改商品分类
     */
    shopCategoryEdit: model => request.put('shop/commodity/category', model),
    /**
     * 获取商品分类
     */
    shopCategoryList: params => request.get('shop/commodity/category/list', { params, no: true }),
    /**
     * 获取商品店铺分类
     */
    shopCategory: (type = 0, parentCategoryId = null) => request.post('shop/commodity/category/shopCategory', { type, parentCategoryId }, { no: true }),
    /**
     * 获取售后保障项
     */
    salseServices: model => request.post('salseServices', model, { no: true }),
    /**
     * 获取平台的商品分类
     */
    getCommodityCategory: model => request.post('shop/getCommodityCategory', model),
    /**
     * 根据店铺商品分类查询商品
     */
    commodityListByShopCategory: model => request.post('shop/commodityListByShopCategory', model),
    /**
     * 查询当前店铺可关联的商品列表
     */
    mainCommodity: model => request.post('shop/mainCommodity', model),
    /**
     * 汇率查询
     */
    cashRate: model => request.post('shop/cashRate', model),
    /**
     * 发布商品
     */
    pushCommodity: model => request.post('shop/pushCommodity', model),
    /**
     * 店铺商品列表
     */
    commodityList: model => request.post('shop/commodityList', model, { no: true }),
    /**
     * 商品下架
     */
    commodityOnOff: model => request.put('shop/commodity/on/sale/batch', { operationType: 2, commodityIdList: [model.commodityId] }),
    /**
     * 删除商品
     */
    commodityRmove: model => request.post('shop/commodityRmove', model),
    /**
     * 获取运费模板
     */
    sendModel: () => request.get('freight/freightList', {}, { no: true }),
    /**
     * 审批列表
     */
    auditList: params => request.get('shop/commodity/audit/list', {
        params,
        no: true
    }),
    /**
     * 仓库中列表
     */
    storeList: params => request.get('shop/commodity/store/list', {
        params,
        no: true
    }),
    /**
     * 删除商品分类
     */
    deleteClass: id => request.delete('shop/commodity/category/' + id, { no: true }),
    /**
     * 商品分类
     */
    commodityClass: parentCategoryId => request.get('common/commodity/category/list', { params: { parentCategoryId }, no: true }),
    /**
     * 品牌列表
     */
    brandList: brandName => request.get('common/brand/list', { params: { brandName }, no: true }),
    /**
     * 商品详情
     */
    goodsDetail: id => request.get('shop/commodity/on/sale/' + id, { no: true }),
    /**
     * 更新商品
     */
    updateGoods: model => request.put('shop/commodity/on/sale', model),
    /**
     * 批量更新
     */
    batchUpdate: model => request.put('shop/commodity/on/sale/batch', model),
    /**
     * 订单详情
     */
    orderDetail: id => request.get('order/all/' + id, { no: true }),
    /**
     * 根据商品查询商品规格来设置活动价
     */
    getCommoditySpec: commodityId => request.post('getCommoditySpec', { commodityId }, { no: true }),
    /**
     * 货币列表
     */
    currency: () => request.get('index/currency', { no: true }),
    /**
     * 获取关联商品列表
     */
    isMain: () => request.get('shop/commodity/on/sale/isMain', { no: true }),
    /**
     * 关联分类
     */
    relateCategory: parent => request.get('shop/commodity/category/relevanceCategory', { params: { parent }, no: true })
}