<template>
  <AConfigProvider :input="{ autocomplete: 'off' }" dropdownMatchSelectWidth :autoInsertSpaceInButton="false"
    componentSize="large" :direction="$i18n.locale === 'ar' ? 'rtl' : 'ltr'" :form="{ colon: false }"
    :locale="$i18n.locale === 'en' ? en : $i18n.locale === 'zh' ? zh : ar">
    <router-view />
  </AConfigProvider>
</template>
<script setup>
import { getCurrentInstance, provide, watch } from 'vue';
import { i18nChangeLanguage } from '@wangeditor/editor'
import en from 'ant-design-vue/es/locale/en_US';
import zh from 'ant-design-vue/es/locale/zh_CN';
import ar from 'ant-design-vue/es/locale/ar_EG';

const { appContext: { config: { globalProperties: { $t: t, $i18n: l } } } } = getCurrentInstance();

watch(() => l.locale, v => {
  i18nChangeLanguage(v === 'zh' ? 'zh-CN' : v);
});
provide('l', l);
provide('t', t);
provide('time', 'YYYY-MM-DD HH:mm:ss');
</script>
<style lang="scss"></style>
