<template>
    <div class="space-wrapper"
        :style="{ justifyContent: center ? 'center' : end ? 'flex-end' : between ? 'space-between' : '', alignItems: align, direction: $i18n.locale === 'ar' ? 'rtl' : 'ltr', flexDirection: column ? 'column' : 'row', display: flex ? 'flex' : 'inline-flex', flexWrap: wrap ? 'wrap' : 'nowrap', gap: typeof size === 'string' ? size : size + 'px' }">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Space',
    props: {
        /**
         * justify-content end
         */
        end: Boolean,
        /**
         * justify-content space-between
         */
        between: Boolean,
        /**
         * justify-content center
         */
        center: Boolean,
        /**
         * 垂直对齐方式
         */
        align: {
            type: String,
            default: 'center'
        },
        /**
         * 竖
         */
        column: Boolean,
        /**
         * 是否换行
         */
        wrap: Boolean,
        /**
         * 是否为flex
         */
        flex: Boolean,
        /**
         * 间距
         */
        size: {
            type: [String, Number],
            default: 10
        }
    }
}
</script>

<style lang="scss" scoped></style>