/**
 * 用户接口
 */

import request from './request';

export default {
    /**
     * 用户登录
     */
    userLogin: model => request.post('userLogin', model),
    /**
     * 获取验证码
     */
    getMessage: model => request.post('getMessage', model),
    /**
     * 重置密码
     */
    resetPassword: model => request.post('resetPassword', model),
    /**
     * 查询店铺信息
     */
    shopInfo: model => request.post('shopInfo', model),
    /**
     * 查询收货地址省市区信息(多级联动)
     */
    areaList: pid => request.get('common/area/list', {
        params: {
            pid
        },
        no: true
    }),
    /**
     * 上传
     */
    upload: files => {
        const form = new FormData();
        files.forEach(file => {
            form.append('multipartFiles', file);
        });
        return request.post('/public/uploadfiles', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}