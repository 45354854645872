export default {
    topClass: 'Top Category',
    thisWeek: 'Last week',
    thisMonth: 'Last month',
    fullYear: 'Last year',
    salesMoney: 'Sales',
    salesMoneyTrend: 'Sales Trend',
    captchaPh: 'done in [s]s.',
    submitAudit: 'Submit',
    unit: 'Unit',
    ge: 'pc.',
    tiji: 'volume',
    weight: 'weight',
    viewStoreCategory: 'Store Type',
    goodsBrandPh: 'Select up to 10 type',
    editGoodsDetail: 'Edit Product Details',
    goodsBrand: 'Product Brand',
    storeCategory: 'Store Category',
    prevStep: 'previous',
    putInRepository: 'Add to storage',
    maintainKeep: 'After Sales Support',
    sameFee: 'Unified Freight Rate',
    transportTemplate: 'Freight Rate Template',
    sendTime: 'Shipping Time',
    sendTimeA: 'Shipping Time:',
    forgotPassword: 'Forgot password',
    transportFee: 'Shipping Fees',
    skuWarnPh: 'When the total inventory of the product reaches the warning value, the inventory status is in warning status; when the inventory is 0, the product is removed and added to the warehouse',
    skuWarn: 'Inventory Warning Level',
    salesCountPh: 'If not filled in, it will be displayed based on actual sales',
    sku: 'Inventory',
    exitsSameValue: 'The same value already exists',
    weightG: 'Weight (g)',
    chengben: 'Cost Price',
    priceYuanA: 'Price',
    specificationsImagePh: 'Suggested dimensions 750*750 pixels',
    specificationsImage: 'Specifications',
    specificationsDetail: 'Specifications Details',
    addSpecifications: 'Add Specification',
    addSpecificationsValue: 'Add Specification Value',
    specificationsValue: 'Specification Value',
    specificationsName: 'Specification Name',
    goodsSpecifications: 'Product Specifications',
    favor: 'Save',
    repository: 'in the warehouse',
    resubmit: 'Re-submit',
    auditStatusA: 'Approval Status',
    goodsClassify: 'Products Categories',
    goodsManage: 'Product Management',
    unAudit: 'Under Review',
    auditRejected: 'Approval Denied',
    canBackScore: 'Points Reward:',
    paiedMoneyA: 'Due Amount:',
    postFee: 'Shipping Fees:',
    includePostFee: 'Shipping Fees Incl.:',
    goodsTotalPrice: 'Total Price:',
    payOptions: 'Payment Method',
    payTime: 'Payment Time',
    orderInfo: 'Order Information',
    concater: 'Contact',
    receiveAddressA: 'Delivery Address',
    agreeMaintain: 'After Sales Agreed',
    agree: 'Yes',
    selectTransport: 'Choose Logistics',
    transportNo: 'Shipping No.',
    sendGoods: 'Ship',
    today: 'Today',
    near7Days: 'in 7 days',
    near30Days: 'in 30 days',
    priceA: 'Unit Price',
    unConfirm: 'To be confirmed',
    payOnline: 'Online Payment',
    orderTimeA: 'Order Time',
    cancelRecommend: 'Cancel',
    deleteRejected: 'Delete',
    deleteAuding: 'Delete Processing',
    recommend: 'Recommend',
    applyDelete: 'Request the deletion',
    sellerReplyContent: 'Seller Reply',
    commentImage: 'Review Photo',
    commentContent: 'Review Content',
    rate: 'Review Stars',
    orderNoA: 'Order No.',
    rateFilter: 'Sort by stars',
    commentTime: 'Review Time',
    fiveStars: '5 Stars / *****',
    fourStars: '4 Stars / ****',
    threeStars: '3 Stars / ***',
    twoStars: '2 Stars /**',
    oneStars: '1 Star / *',
    noStars: 'None',
    usernameA: 'Username',
    skuOrSpecifications: 'Inventory / Specifications',
    mulImagePh: 'Upload 5 photos, the first one is the thumbnail of the products list, the recommended size is 750*750 pixels',
    originPrice: 'Original Price',
    reSelectClass: 'Reselect Category',
    selectedClass: 'Selected Category',
    pleaseSelectClass: 'Please Select Category',
    nextStep: 'Next',
    baseinfo: 'Basic Information',
    goodsDetail: 'Product Details',
    selectClass: 'Select Category',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    top: 'Pin to Top',
    cancelTop: 'Cancel pin to top',
    postStatus: 'Status',
    sellCount: 'Sales Volume',
    comment: 'Review',
    clickCount: 'Number of Clicks',
    totalSku: 'Total Inventory',
    sellPrice: 'Selling Price',
    goodsMainImage: 'Product Main Photo',
    selling: 'On Sale',
    classify: 'Categories',
    remove: 'Remove',
    editClass: 'Edit Category',
    belongsClass: 'All Categories',
    priceYuan: 'Selling Price',
    batchClassify: 'Batch Classification',
    parentClassA: 'Parent Category',
    parentClass: 'Parent Category',
    className: 'Category Name',
    selectFirstClass: 'Select Category',
    publishGoods: 'Publish Product',
    yes: 'Yes',
    no: 'No',
    goodsTitle: 'Product Title',
    updateTime: 'Update Time',
    days: 'Number of Days',
    date: 'Date',
    canWithdraw: 'Withdraw Permitted',
    withdrawServiceCharge: 'Withdraw Fees',
    goWrite: 'Fill in',
    writeBankcard: 'You have not filled in the bank card information',
    platformFeedback: 'Platform Feedback',
    auditTime: 'Approval Time',
    postTime: 'Submission Time',
    withdrawAccount: 'Withdrawal Account',
    serviceCharge: 'Fees',
    withdrawMoney: 'Withdrawal Amount',
    withdrawedMoney: 'Withdrawn Amount:',
    freezeMoney: 'Frozen Amount:',
    canWithdrawMoney: 'Permitted Withdrawal Amount:',
    withdraw: 'Withdraw',
    withdrawTime: 'Withdraw Date',
    withdrawFailed: 'Withdrawal Failed',
    passed: 'Approved',
    pleaseSelect: 'Please Select',
    withdrawStatus: 'Withdrawal Status',
    orderNoA: 'Order No.',
    earnMoney: 'Credited Amount',
    repay: 'Refund',
    percentage: 'Platform Commission',
    earn: 'Incomes',
    earnTime: 'Credit Date',
    earnType: 'Incomes & Expenses Type',
    concatType: 'Contact',
    orderBuyer: 'User',
    picPH: 'Recommended size width 260*height 150 pixels',
    coverImage: 'Cover Image',
    relationGoods: 'Related Products',
    partyName: 'Activity Name',
    partyManage: 'Activity Management',
    removeGoods: 'Remove Product',
    addGoods: 'Add Product',
    checkedGoods: 'Selected Product',
    unsetCouponGoods: 'Products without coupons',
    searchGoods: 'Search Product',
    goodsName: 'Product Name',
    couponCanUseGoods: 'Products with coupons',
    allGoods: 'All Products',
    partyGoods: 'Some products',
    canUseGoods: 'Available Products',
    availableDays: 'Valid for ….. Days',
    couponStart: 'Starts on coupon pickup date',
    fixedDate: 'Fixed Days',
    unFixedDate: 'Variable',
    publish: 'On Shelf',
    down: 'Off Shelf',
    couponStatus: 'Coupons Status',
    canUse: 'Buy …. to use',
    full: '',
    limit: 'Limited',
    noLimit: 'Not Limited',
    useConditionPh: 'Please set up carefully no threshold coupons to avoid loss',
    useCondition: 'Threshold for use',
    mianzhi: 'Value',
    yuan: 'USD',
    discountPh: 'Coupons cannot be used to reduce shipping fees',
    discountA: 'Discount',
    fullReduce: 'Buy …. get …% off',
    cheapType: 'Coupons Types',
    givenCountPh: '-1 for unlimited number',
    publishTime: 'Post Date',
    usedCount: 'Usage Quantity',
    gotCount: 'Pickup Quantity',
    givenCount: 'Posted Quantity',
    valueOrDiscount: 'Value/Discount',
    description: 'Description',
    availableDate: 'Validity',
    couponName: 'Coupon Name',
    moneySign: '$[m]',
    bondTime: 'Payment Time',
    expiredTime: 'Expiry Time',
    jiaonaMoney: 'Payment Amount',
    payBond: 'Pay Deposit',
    payLog: 'Payment Record',
    bondMoney: 'Deposit Amount',
    successSelling: 'Normal Operation',
    tempClose: 'Temporarily closed',
    apiName: 'Interface Name',
    apiUrl: 'Interface URL',
    apiCode: 'Interface Code',
    customerServiceSettings: 'Customer Service Configuration',
    createdBank: 'Bank Name',
    bankCardNo: 'Bank Card Name',
    phone: 'Mobile No.',
    companyName: 'Company Name',
    name: 'Full Name',
    bankCardInfo: 'Bank Card Information',
    storeStatus: 'Store Status',
    choseCountryPh: 'Select Country',
    choseProvincePh: 'Select Province',
    choseCityPh: 'Select City',
    choseDistrictPh: 'Select District',
    submitToAudit: 'Submit',
    changeAvatar: 'Change Photo',
    invalidFormat: '[t] Incorrect Format',
    confirmPassword: 'Confirm Password',
    originPassword: 'Old Password',
    editInfo: 'Edit Information',
    resetPassword: 'Reset Password',
    manageLicense: 'Business license and industry-related qualification certificates',
    authBrand: 'Authorized Brand',
    storeAddress: 'Store Address',
    emailAddress: 'Email',
    concatPhone: 'Phone',
    sellerName: 'Merchant Name',
    storeAvatar: 'Merchant Photo',
    replyMessage: 'Reply Message',
    reply: 'Reply',
    replyTime: 'Reply Time',
    replyContent: 'Reply Text',
    leaveTime: 'Message Time',
    leaveMessage: 'Message Text',
    delete: 'Delete',
    required: '[t] Cannot be empty',
    minMax: 'Length min [min] max [max]',
    min: 'Minimum Length [min]',
    max: 'Maximum Length [max]',
    total: 'Total [total]',
    search: 'search',
    exit: 'quit',
    receiveTime: 'Receiving Time',
    messageContent: 'Message Content',
    titleA: 'Subject',
    buyerMessage: 'Buyer Message',
    notifyMessage: 'Notification',
    role: 'Role',
    position: 'Job Title',
    rights: 'Permissions',
    enabled: 'Enable',
    disabled: 'Disable',
    askDelete: 'Are you sure you want to delete?',
    addRole: 'Add Role',
    editRole: 'Edit Role',
    edit: 'Edit',
    status: 'Status',
    add: 'Add',
    createTime: 'Creation Time',
    roleName: 'Role Name',
    passNoMatch: 'Passwords do NOT match',
    ask: 'Inquiry',
    askExit: 'Are you sure to quit?',
    newPassword: 'New Password',
    captcha: 'Verification Code',
    phoneOrEmail: 'Mobile No./Email',
    skuWarn: 'Inventory Warning',
    goodsCount: 'Total number of products',
    gotCount: 'Received',
    couponCount: 'Total Coupons',
    notStart: 'Not started',
    going: 'In-progress',
    partyCount: 'Total number of activities',
    allSalesMoney: 'Total Sales',
    perDaySalesMoney: 'Daily Sales',
    orderCount: 'Total Orders',
    perDayOrderCount: 'Daily Orders',
    willRepay: 'Refund Processing',
    shopManager: 'Merchant Back Office',
    language: 'language',
    title: 'Merchant Back Office Management System',
    zh: '简体中文',
    en: 'English',
    ar: 'العربية',
    moneyMark: '$',
    paymentOptions: 'Payment Method',
    paypall: 'PayPall',
    stripe: 'Stripe',
    receivePay: 'Pay on delivery',
    qiCard: 'Qi Card',
    discount: '',
    key: 'keywords',
    orderType: 'Order Type',
    orderStatus: 'Order Status',
    orderTime: 'Transaction Time',
    startTime: 'Start time',
    endTime: 'End Time',
    to: 'to',
    allOrder: 'All Orders',
    unPay: 'Pending Payment',
    unSend: 'To be shipped',
    unReceive: 'To be delivered',
    unAssem: 'To be evaluated',
    maintaining: 'After Sales',
    search: 'Search',
    baby: 'Product',
    price: 'Price',
    count: 'Qty.',
    paiedMoney: 'Due Amount',
    action: 'Action',
    orderTime: 'Order Time:',
    orderNo: 'Order No.: ',
    orderDetail: 'Order Details',
    pay: 'Confirm',
    cancel: 'Cancel',
    applyReback: 'Request a Refund',
    confirmReceive: 'Confirm Delivery',
    viewTansform: 'Track your Order',
    confirm: 'Submit',
    confirmReceived: 'Confirm Delivery',
    close: 'Close',
    ok: 'Confirm',
    transformCompany: 'Delivery Company',
    transformNo: 'Tracking No.',
    transformCompanyA: 'Delivery Company:',
    transformNoA: 'Tracking No.:',
    afterServiceTime: 'After sales time:',
    concatPhone: 'Contact Number',
    receiveAddress: 'Recipient Address',
    save: 'Save',
    score: 'Reward Points',
    sort: 'Sort',
    reset: 'Reset',
    uploadImage: 'Upload Image',
    repayMoney: 'Refunded Amount',
    transformInfo: 'Shipping Information',
    remark: 'Notes:',
    post: 'Express',
    back: 'Back',
    emailAddress: 'Email Address',
    storeAddress: 'Store Address',
    choseCountryPh: 'Select Country',
    choseProvincePh: 'Select Province',
    choseCityPh: 'Select City',
    manageClass: 'Business Category',
    englishManageClass: 'Categories in English Version',
    arManageClass: 'Categories in Arabic Version',
    brandAuth: 'Brand Authorization Documents',
    auditing: 'Processing',
    auditSuccess: 'Approved',
    username: 'Username',
    password: 'Password',
    login: 'Sign in',
    getCaptcha: 'Get verification code',
    recommendGoods: 'Recommended Products',
    allGoods: 'All Products',
    askDeleteA: 'Are you sure you want to delete?',
    askDown: 'Are you sure you want to remove the product?',
    buyer: 'buyer:',
    speEmpty: 'Cannot be empty',
    ended: 'Done',
    other: 'Others',
    askPost: 'Are you sure you want to publish the product?',
    unJoinActiveGoods: 'Products not participating in the current activity',
    activePrice: 'Activity Price',
    choseGoods: 'Select Product',
    rejected: 'Reject',
    shengxiao: 'Valid',
    repaying: 'Applying for a refund',
    repied: 'Refunded',
    askApplyRepay: 'Are you sure you want to request a refund?',
    activeGoods: 'Activity Products',
    editActivePrice: 'edit activity price',
    setEditActivePrice: 'Set/edit activity price',
    specification: 'Specifications',
    activeSku: 'Activity Stock',
    setActivePrice: 'set activity price',
    skuA: 'Stock:[t]',
    orderManage: 'Orders Management',
    tradeDone: 'Transaction completed',
    askCancel: 'Are you sure you want to cancel?',
    tradeCancel: 'Cancel transaction',
    rejectMaintain: 'Reject refund/after sale',
    rePaybackReason: 'Reasons for rejection',
    maintainUnsend: 'After-sale approved, to send back the product',
    rejectReceive: 'Reject delivery',
    sendUnConfirm: 'Product return to be confirmed',
    appealling: 'Processing Claim',
    appealPassed: 'Claim accepted',
    repaybacking: 'Refunding',
    askConfirmReceive: 'Did you receive the order and confirm it?',
    tradeStatus: 'Transaction Status:[s]',
    maintainDesc: 'After-sales Description',
    returnType: 'Refund Type',
    onlyRepayback: 'Refund Only',
    returnGoods: 'Return & Refund',
    returnMoney: 'Refunded Amount:',
    auditSucess: 'Approved',
    goodsTiltePh: 'Maximum 200 characters for product title',
    unPaied: 'Unpaid',
    actionType: 'Action Type',
    publishNew: 'Post a new product',
    relateGoods: 'Link existing products',
    goodsId: 'Linked Product ID',
    goodsIdPh: 'Please enter linked Product ID',
    currency: 'Currency',
    replyContentEmpty: 'Cannot be empty',
    orderDetailA: 'Order Details',
    statistics: 'Operation overview',
    commentManage: 'Review Management',
    withdrawLog: 'Withdrawals Record',
    shopSettings: 'Merchant settings',
    baseInfo: 'Basic Information',
    otherSettings: 'Other Configuration',
    bond: 'Deposit',
    rightsManage: 'Permissions Management',
    rolesManage: 'Roles Management',
    adminManage: 'Administrators',
    runManage: 'Operations Management',
    couponManage: 'Coupon Management',
    financeManage: 'Financial Management',
    transformSettings: 'Shipping Settings',
    gotoSetTransformTemplate: 'Shipping template not set yet   Go to settings',
    coupon: 'Coupon:',
    wish: 'Wish list',
    currentSettings: 'Currency Settings',
    chineseRelateCurrency: 'Currencies related to Chinese:',
    englishRelateCurrency: 'Currencies related to English:',
    arRelateCurrency: 'Currencies related to Arabic:',
    used: 'Used',
    to: 'to',
    viewSendBackTransform: 'View return shipping',
    aRelateGoods: 'Chinese  related products:',
    bRelateGoods: 'Arabic related products:',
    chineseRelateCategory: 'Chinese related product classification',
    englishRelateCategory: 'English related product classification',
    arabRelateCategory: 'Arabic related product classification',
    currencyTip: 'Please confirm your selected currency, It cannot be changed after confirmation.',
    mixPay: '混合支付',
    balancePay: '余额支付'
}