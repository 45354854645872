export default {
    topClass: 'الفئة الأعلى',
    thisWeek: 'خلال أسبوع',
    thisMonth: 'خلال شهر',
    fullYear: 'خلال سنة',
    salesMoney: 'المبيعات',
    salesMoneyTrend: 'اتجاه المبيعات',
    captchaPh: 'يتم خلال [s] ث',
    submitAudit: 'إرسال',
    unit: 'وحدة',
    ge: 'قطعة',
    tiji: 'حجم',
    weight: 'وزن',
    viewStoreCategory: 'نوع المتجر',
    goodsBrandPh: 'يمكن اختيار 10 أنواع',
    editGoodsDetail: 'تعديل تفاصيل المنتج',
    goodsBrand: 'ماركة المنتج',
    storeCategory: 'فئة المتجر',
    prevStep: 'السابق',
    putInRepository: 'أضف إلى المخزن',
    maintainKeep: 'خدمة مابعد البيع',
    sameFee: 'رسوم شحن موحدة',
    transportTemplate: 'نموذج رسوم الشحن',
    sendTime: 'وقت الشحن',
    sendTimeA: ':وقت الشحن',
    forgotPassword: 'نسيت كلمة السر',
    transportFee: 'رسوم الشحن',
    skuWarnPh: 'عندما يصل المخزون الإجمالي للمنتج إلى مستوى التحذير، تكون حالة المخزون هي حالة التحذير، عندما يكون المخزون صفر، تتم إزالة المنتج وإضافته إلى المستودع',
    skuWarn: 'مستوى التحذير للمخزون',
    salesCountPh: 'في حال عدم ملئ الخانة، سيتم تحديده بناءا على حجم المبيعات الفعلي',
    sku: 'المخزون',
    exitsSameValue: 'نفس القيمة موجودة',
    weightG: 'الوزن (غ)',
    chengben: 'سعر التكلفة',
    priceYuanA: 'السعر',
    specificationsImagePh: 'الحجم المقترح 750 * 750 بيكسل',
    specificationsImage: 'المواصفات',
    specificationsDetail: 'تفاصيل المواصفات',
    addSpecifications: 'إضافة مواصفات',
    addSpecificationsValue: 'إضافة قيمة المواصفات',
    specificationsValue: 'قيمة المواصفات',
    specificationsName: 'اسم المواصفات',
    goodsSpecifications: 'مواصفات المنتج',
    favor: 'حفظ',
    repository: 'في المخزن',
    resubmit: 'إعادة إرسال',
    auditStatusA: 'حالة الموافقة',
    goodsClassify: 'أصناف المنتجات',
    goodsManage: 'إدارة المنتجات',
    unAudit: 'قيد المراجعة',
    auditRejected: 'رفض الموافقة',
    canBackScore: 'إرجاع نقاط:',
    paiedMoneyA: 'المبلغ المطلوب:',
    postFee: 'رسوم الشحن:',
    includePostFee: 'مع رسوم الشحن:',
    goodsTotalPrice: 'السعر الكلي:',
    payOptions: 'طرق الدفع',
    payTime: 'توقيت الدفع',
    orderInfo: 'تفاصيل الطلب',
    concater: 'المتصل به',
    receiveAddressA: 'عنوان التسليم',
    agreeMaintain: 'موافقة لما بعد البيع',
    agree: 'نعم',
    selectTransport: 'اختيار شركة الشحن',
    transportNo: 'رقم الشحن',
    sendGoods: 'شحن',
    today: 'اليوم',
    near7Days: 'خلال 7 أيام',
    near30Days: 'خلال 30 يوم',
    priceA: 'سعر الوحدة',
    unConfirm: 'بانتظار التأكيد',
    payOnline: 'دفع إلكتروني',
    orderTimeA: 'توقيت الطلب',
    cancelRecommend: 'إلغاء',
    deleteRejected: 'حذف',
    deleteAuding: 'مراجعة الحذف',
    recommend: 'توصية',
    applyDelete: 'طلب حذف',
    sellerReplyContent: 'رد البائع',
    commentImage: 'صورة التقييم',
    commentContent: 'محتوى التقييم',
    rate: 'التقييم بالنجوم',
    orderNoA: 'رقم الطلب',
    rateFilter: 'ترتيب حسب النجوم',
    commentTime: 'توقيت التقييم',
    fiveStars: '5 نجوم / *****',
    fourStars: '4 نجوم / ****',
    threeStars: '3 نجوم / ***',
    twoStars: 'نجمتان / **',
    oneStars: 'نجمة / *',
    noStars: 'لا شيئ',
    usernameA: 'إسم مستخدم',
    skuOrSpecifications: 'المخزون / المواصفات',
    mulImagePh: 'قم بتحميل 5 صور ، الأولى هي الصورة المصغرة لقائمة المنتجات، الحجم الموصى به هو 750 * 750 بكسل',
    originPrice: 'السعر الأصلي',
    reSelectClass: 'إعادة تحديد الفئة',
    selectedClass: 'الفئة المحددة',
    pleaseSelectClass: 'الرجاء تحديد الفئة',
    nextStep: 'التالي',
    baseinfo: 'معلومات أساسية',
    goodsDetail: 'تفاصيل المنتج',
    selectClass: 'تحديد الفئة',
    moveUp: 'للأعلى',
    moveDown: 'للأسفل',
    top: 'تثبيت في الأعلى',
    cancelTop: 'إلغاء التثبيت في الأعلى',
    postStatus: 'الحالة',
    sellCount: 'حجم المبيعات',
    comment: 'تقييم',
    clickCount: 'عدد النقرات',
    totalSku: 'المخزون الإجمالي',
    sellPrice: 'سعر البيع',
    goodsMainImage: 'الصورة الرئيسية للمنتج',
    selling: 'معروض للبيع',
    classify: 'الفئات',
    remove: 'حذف',
    editClass: 'تعديل الفئة',
    belongsClass: 'جميع الفئات',
    priceYuan: 'سعر البيع',
    batchClassify: 'تصنيف بالجملة',
    parentClassA: 'الفئة الرئيسية',
    parentClass: 'الفئة الرئيسية',
    className: 'إسم الفئة',
    selectFirstClass: 'اختر الفئة',
    publishGoods: 'أنشر المنتج',
    yes: 'نعم',
    no: 'لا',
    goodsTitle: 'عنوان المنتج',
    updateTime: 'تاريخ التحديث',
    days: 'عدد الأيام',
    date: 'التاريخ',
    canWithdraw: 'يمكن السحب',
    withdrawServiceCharge: 'رسوم السحب',
    goWrite: 'ملئ المعلوامات',
    writeBankcard: 'لم تقم بملئ معلومات البطاقة المصرفية',
    platformFeedback: 'ردود المنصة',
    auditTime: 'مدة المراجعة',
    postTime: 'وقت الإرسال',
    withdrawAccount: 'حساب السحب',
    serviceCharge: 'الرسوم',
    withdrawMoney: 'مبلغ السحب',
    withdrawedMoney: 'المبلغ المسحوب',
    freezeMoney: 'المبلغ المجمد',
    canWithdrawMoney: 'المبلغ المسموح للسحب',
    withdraw: 'سحب',
    withdrawTime: 'تاريخ السحب',
    withdrawFailed: 'فشل السحب',
    passed: 'تمت الموافقة',
    pleaseSelect: 'الرجاء الاختيار',
    withdrawStatus: 'حالة السحب',
    orderNoA: 'رقم الطلب',
    earnMoney: 'المبلغ المستلم',
    repay: 'استرداد',
    percentage: 'عمولة المنصة',
    earn: 'المداخيل',
    earnTime: 'تاريخ الاستلام',
    earnType: 'نوع المداخيل والمصاريف',
    concatType: 'عناوين التواصل',
    orderBuyer: 'المستخدم',
    picPH: 'الحجم الموصى به العرض 260 * الارتفاع 150 بكسل',
    coverImage: 'صورة الغلاف',
    relationGoods: 'منتجات ذات صلة',
    partyName: 'إسم النشاط',
    partyManage: 'إدارة النشاط',
    removeGoods: 'حذف منتج',
    addGoods: 'إضافة منتج',
    checkedGoods: 'المنتج المحدد',
    unsetCouponGoods: 'منتجات بدون كوبونات',
    searchGoods: 'البحث عن منتج',
    goodsName: 'إسم المنتج',
    couponCanUseGoods: 'منتجات لديها كوبونات',
    allGoods: 'جميع المنتجات',
    partyGoods: 'بعض المنتجات',
    canUseGoods: 'المنتجات المتاحة',
    availableDays: 'صالح ل..... يوم / أيام',
    couponStart: 'يبدأ من تاريخ استلام الكوبون',
    fixedDate: 'أيام ثابتة',
    unFixedDate: 'متغيرة',
    publish: 'قيد البيع',
    down: 'غير متوفر',
    couponStatus: 'حالة الكوبونات',
    canUse: 'يستعمل عند شراء أكثر من ....',
    full: '',
    limit: 'محدود(ة)',
    noLimit: 'غير محدود(ة)',
    useConditionPh: 'يرجى تحديد الكوبونات المفتوحة بعناية لتجنب الخسارة',
    useCondition: 'عتبة الاستخدام',
    mianzhi: 'القيمية',
    yuan: '',
    discountPh: 'لا يمكن استعمال الكوبونات لخصم مصاريف الشحن',
    discountA: 'تخفيض',
    fullReduce: 'اشتر ... واحصل على ...% تخفيض',
    cheapType: 'أنواع الكوبونات',
    givenCountPh: '-1 لعدد غير محدود',
    publishTime: 'تاريخ النشر',
    usedCount: 'الكمية المستخدمة',
    gotCount: 'الكمية المستلمة',
    givenCount: 'الكمية المنشورة',
    valueOrDiscount: 'القيمة/التخفيض',
    description: 'الوصف',
    availableDate: 'مدة الصلاحية',
    couponName: 'إسم الكوبون',
    moneySign: '[m]',
    bondTime: 'تاريخ الدفع',
    expiredTime: 'تاريخ انتهاء الصلاحية',
    jiaonaMoney: 'المبلغ المدفوع',
    payBond: 'دفع مبلغ الضمان',
    payLog: 'سجل الدفع',
    bondMoney: 'مبلغ الضمان',
    successSelling: 'تشغيل عادي',
    tempClose: 'مغلق مؤقتا',
    apiName: 'إسم الواجهة',
    apiUrl: 'رابط الواجهة',
    apiCode: 'كود الواجهة',
    customerServiceSettings: 'إعدادات خدمة العملاء',
    createdBank: 'إسم البنك',
    bankCardNo: 'رقم البطاقة المصرفية',
    phone: 'رقم الجوال',
    companyName: 'إسم الشركة',
    name: 'الإسم الكامل',
    bankCardInfo: 'معلومات البطاقة المصرفية',
    storeStatus: 'حالة المتجر',
    choseCountryPh: 'اختر الدولة',
    choseProvincePh: 'اختر المحافظة',
    choseCityPh: 'اختر المدينة',
    choseDistrictPh: 'اختر المنطقة',
    submitToAudit: 'إرسال',
    changeAvatar: 'تغيير الصورة',
    invalidFormat: '[t] الصيغة خطأ',
    confirmPassword: 'تأكيد كلمة المرور',
    originPassword: 'كلمة المرور القديمة',
    editInfo: 'تعديل المعلومات',
    resetPassword: 'إعادة تعيين كلمة المرور',
    manageLicense: 'الرخصة التجارية والشهادات ذات الصلة بمجال العمل',
    authBrand: 'العلامة التجارية المعتمدة',
    manageClass: 'نوع العمل',
    englishManageClass: 'الأقسام في النسخة الانجليزية',
    arManageClass: 'الأقسام في النسخة العربية',
    storeAddress: 'عنوان المتجر',
    emailAddress: 'البريد الالكتروني',
    concatPhone: 'الهاتف',
    sellerName: 'إسم البائع',
    storeAvatar: 'صورة البائع',
    replyMessage: 'الرد على الرسالة',
    reply: 'الرد',
    replyTime: 'توقيت الرد',
    replyContent: 'نص الرد',
    leaveTime: 'توقيت الرسالة',
    leaveMessage: 'نص الرسالة',
    delete: 'حذف',
    required: '[t] لا يمكن ترك الخانة فارغة',
    minMax: 'الطول الأدنى [min] الأقصى [max]',
    min: 'الطول الأدنى [min]',
    max: 'الطول الأقصى [max]',
    total: 'المجموع [total]',
    search: 'بحث',
    exit: 'خروج',
    receiveTime: 'وقت الاستلام',
    messageContent: 'محتوى الرسالة',
    titleA: 'الموضوع',
    buyerMessage: 'رسالة المشتري',
    notifyMessage: 'إشعار',
    role: 'الدور',
    position: 'الوظيفة',
    rights: 'الصلاحيات',
    enabled: 'تفعيل',
    disabled: 'إيقاف',
    askDelete: 'هل أنت متأكد أنك تريد الحذف؟',
    addRole: 'إضافة دور',
    editRole: 'تعديل دور',
    edit: 'تعديل',
    status: 'الحالة',
    add: 'إضافة',
    createTime: 'تاريخ الإنشاء',
    roleName: 'إسم الدور',
    passNoMatch: 'كلمات المرور غير متطابقة',
    ask: 'استعلام',
    askExit: 'هل أنت متأكد أنك تريد الخروج؟',
    newPassword: 'كلمة المرور الجديدة',
    captcha: 'رمز التحقق',
    phoneOrEmail: 'الجوال/البريد الإلكتروني',
    skuWarn: 'تحذير المخزون',
    goodsCount: 'العدد الإجمالي للمنتجات',
    gotCount: 'مستلمة',
    couponCount: 'إجمالي الكوبونات',
    notStart: 'لم يبدأ',
    going: 'قيد العمل',
    partyCount: 'إجمالي عدد الأنشطة',
    allSalesMoney: 'إجمالي المبيعات',
    perDaySalesMoney: 'المبيعات اليومية',
    orderCount: 'إجمالي الطلبات',
    perDayOrderCount: 'الطلبات اليومية',
    willRepay: 'الاسترداد قيد المعالجة',
    shopManager: 'نظام إدارة المتاجر',
    language: 'اللغة',
    title: 'نظام إدارة المتاجر',
    zh: '简体中文',
    en: 'English',
    ar: 'العربية',
    moneyMark: '',
    paymentOptions: 'طرق الدفع',
    paypall: 'PayPall',
    stripe: 'سترايب',
    receivePay: 'الدفع عند الاستلام',
    qiCard: 'كي كارد',
    discount: '% تخفيض',
    key: 'الكلمات المفتاحية',
    orderType: 'نوع الطلب',
    orderStatus: 'حالة الطلب',
    orderTime: 'توقيت المعاملة',
    startTime: 'موعد البدء',
    endTime: 'موعد الانتهاء',
    to: 'إلى',
    allOrder: 'جميع الطلبات',
    unPay: 'بانتظار الدفع',
    unSend: 'بانتظار الشحن',
    unReceive: 'بانتظار الاستلام',
    unAssem: 'بانتظار التقييم',
    maintaining: 'ما بعد البيع',
    search: 'بحث',
    baby: 'المنتج',
    price: 'السعر',
    count: 'الكمية',
    paiedMoney: 'المبلغ المطلوب',
    action: 'إجراء',
    orderTime: 'توقيت الطلب:',
    orderNo: 'رقم الطلب: ',
    orderDetail: 'تفاصيل الطلب',
    pay: 'تأكيد',
    cancel: 'إلغاء',
    applyReback: 'طلب استرداد المبلغ',
    confirmReceive: 'تأكيد الاستلام',
    viewTansform: 'تتبع الطلب',
    confirm: 'إرسال',
    confirmReceived: 'تأكيد الاستلام',
    close: 'غلق',
    ok: 'تأكيد',
    transformCompany: 'شركة التوصيل',
    transformNo: 'رقم تتبع الشحنة',
    transformCompanyA: 'شركة التوصيل:',
    transformNoA: 'رقم تتبع الشحنة:',
    afterServiceTime: 'وقت البيع:',
    concatPhone: 'رقم التواصل',
    receiveAddress: 'عنوان المستلم',
    save: 'حفظ',
    score: 'نقاط المكافئات',
    sort: 'ترتيب',
    reset: 'إعادة تعيين',
    uploadImage: 'تحميل صورة',
    repayMoney: 'المبلغ المسترجع',
    transformInfo: 'معلومات الشحن',
    remark: 'ملاحظات:',
    post: 'البريد السريع',
    back: 'رجوع',
    emailAddress: 'عنوان البريد الإلكتروني',
    storeAddress: 'عنوان المتجر',
    choseCountryPh: 'اختر الدولة',
    choseProvincePh: 'اختر المحافظة',
    choseCityPh: 'اختر المدينة',
    brandAuth: 'وثائق التخويل الخاصة بالعلامة التجارية',
    auditing: 'قيد التدقيق',
    auditSuccess: 'تم القبول',
    username: 'إسم مستخدم',
    password: 'كلمة المرور',
    login: 'تسجيل الدخول',
    getCaptcha: 'احصل على رمز التحقق',
    recommendGoods: 'المنتجات الموصى بها',
    allGoods: 'جميع المنتجات',
    askDeleteA: 'هل أنت متأكد أنك تريد الحذف؟',
    askDown: 'هل أنت متأكد أنك تريد حذف المنتج؟',
    buyer: 'المشتري:',
    speEmpty: 'لا يمكن ترك الخانة فارغة',
    ended: 'تم',
    other: 'أخرى',
    askPost: 'هل أنت متأكد أنك تريد نشر المنتج؟',
    unJoinActiveGoods: 'المنتجات غير المشاركة في النشاط الحالي',
    activePrice: 'السعر الحالي',
    choseGoods: 'اختر المنتج',
    rejected: 'رفض',
    shengxiao: 'ساري المفعول',
    repaying: 'طلب استرداد المبلغ',
    repied: 'تم الاسترداد',
    askApplyRepay: 'هل أنت متأكد أنك تريد طلب استرداد المبلغ؟',
    activeGoods: 'منتجات النشاط',
    editActivePrice: 'تعديل السعر الحالي',
    setEditActivePrice: 'تعيين/تعديل السعر الحالي',
    specification: 'المواصفات',
    activeSku: 'مخزون النشاط',
    setActivePrice: 'تعيين السعر الحالي',
    skuA: '[t] المخزون',
    orderManage: 'إدارة الطلبات',
    tradeDone: 'إنتهاء المعاملة',
    askCancel: 'هل أنت متأكد أنك تريد الإلغاء؟',
    tradeCancel: 'إلغاء المعامة',
    rejectMaintain: 'رفض الاسترداد / ما بعد البيع',
    rePaybackReason: 'أسباب الرفض',
    maintainUnsend: 'تمت الموافقة على خدمة بعد البيع، بانتظار إعادة المنتج',
    rejectReceive: 'رفض الاستلام',
    sendUnConfirm: 'بانتظار تأكيد استرجاع المنتج',
    appealling: 'مراجعة الشكوى',
    appealPassed: 'تم قبول الشكوى',
    repaybacking: 'قيد الاسترداد',
    askConfirmReceive: 'هل قمت باستلام الطلب والتأكد منه؟',
    tradeStatus: '[s] حالة المعاملة:',
    maintainDesc: 'وصف ما بعد البيع',
    returnType: 'نوع الاسترداد',
    onlyRepayback: 'استرداد المبلغ فقط',
    returnGoods: 'إرجاع المنتج واستردادالمبلغ',
    returnMoney: 'المبلغ المسترجع:',
    auditSucess: 'تم القبول',
    goodsTiltePh: '200 حرفًا كحد أقصى لعنوان المنتج',
    unPaied: 'غير مدفوع',
    actionType: 'نوع الإجراء',
    publishNew: 'نشر منتج جديد',
    relateGoods: 'ربط المنتجات الحالية',
    goodsId: 'معرف المنتج المرتبط',
    goodsIdPh: 'الرجاء إدخال معرّف المنتج المراد ربطه',
    currency: 'العملة',
    replyContentEmpty: 'لا يمكن ترك الخانة فارغة',
    orderDetailA: 'تفاصيل الطلب',
    statistics: 'نظرة عامة',
    commentManage: 'إدارة التقييم',
    withdrawLog: 'سجل السحب',
    shopSettings: 'إعدادات البائع',
    baseInfo: 'معلومات أساسية',
    otherSettings: 'الإعدادات الأخرى',
    bond: 'الضمان',
    rightsManage: 'إدارة الصلاحيات',
    rolesManage: 'إدارة الأدوار',
    adminManage: 'المسؤولين',
    runManage: 'إدارة العمل',
    couponManage: 'إدارة الكوبونات',
    financeManage: 'الإدارة المالية',
    transformSettings: 'إعدادات الشحن',
    gotoSetTransformTemplate: 'لم يتم تعيين نموذج الشحن بعد   اذهب للاعدادات',
    coupon: 'كوبونات:',
    wish: 'رغبة واحدة',
    currentSettings: 'إعدادات العملة ',
    chineseRelateCurrency: 'العملات المرتبطة باللغة الصينية:',
    englishRelateCurrency: 'العملات المرتبطة باللغة الانجليزية:',
    arRelateCurrency: 'العملات المرتبطة باللغة العربية:',
    viewSendBackTransform: 'عرض تفاصيل الشحن لإرجاع المنتج',
    used: 'تستخدم بالفعل',
    to: 'إلى',
    aRelateGoods: 'المنتجات ذات الصلة باللغة الصينية:',
    bRelateGoods: 'المنتجات ذات الصلة باللغة الإنجليزية:',
    chineseRelateCategory: 'تصنيف المنتجات الصينية ذات الصلة',
    englishRelateCategory: 'تصنيف المنتجات ذات الصلة باللغة الإنجليزية',
    arabRelateCategory: 'تصنيف المنتجات ذات الصلة باللغة العربية',
    currencyTip: 'يرجى تأكيد العملة التي اخترتها، ولا يمكن تغييرها بعد التأكيد.',
    mixPay: '混合支付',
    balancePay: '余额支付'
}