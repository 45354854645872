<template>
    <AForm hideRequiredMark ref="formRef" :colon="false" :layout="layout" :model="model" v-bind="$attrs">
        <AFormItem :style="item.itemStyle"
            :labelCol="{ style: { width: layout === 'inline' ? '' : lw, ...item.labelStyle } }"
            v-for="(item, key) in opts" :key="key" :label="item.title" :name="key" :rules="item.rules">
            <Space flex>
                <AInput v-if="item.tag === 'input'" :style="{ width: item.width }" v-model:value="model[key]"
                    :min="item.min" :max="item.max" :placeholder="item.ph" :maxlength="item.max" :type="item.type"
                    :disabled="item.dis" :addonAfter="item.after" :addonBefore="item.before" />
                <ASelect :style="{ width: item.width }" v-else-if="item.tag === 'select'" v-model:value="model[key]"
                    :placeholder="item.ph" :disabled="item.dis" :options="item.values" :fieldNames="item.fieldNames"
                    :allowClear="item.clear" @change="item.change" />
                <ARadioGroup @change="item.change" :style="{ width: item.width }" v-else-if="item.tag === 'radio'"
                    v-model:value="model[key]">
                    <ARadio @click="item.click && item.click(childkey)" v-for="(child, childkey) in item.values"
                        :key="childkey" :value="/^\d+$/.test(childkey) ? childkey * 1 : childkey">
                        {{ child }}</ARadio>
                </ARadioGroup>
                <AInputNumber :style="{ width: item.width }" v-else-if="item.tag === 'number'"
                    v-model:value="model[key]" :addonAfter="item.after" :addonBefore="item.before" :max="item.max"
                    :placeholder="item.ph" />
                <ACascader v-else-if="item.tag === 'cascader'" v-model:value="model[key]" :style="{ width: item.width }"
                    :fieldNames="item.fieldNames" :options="item.values" expandTrigger="hover" />
                <DatePicker v-else-if="item.tag === 'date'" v-model:value="model[key]" :style="{ width: item.width }"
                    :type="item.type" />
                <DateRange v-else-if="item.tag === 'daterange'" v-model:value="model[key]"
                    :style="{ width: item.width }" />
                <ATree v-else-if="item.tag === 'tree'" :checkable="item.checkable" :treeData="item.values"
                    :fieldNames="item.fields" v-model:checkedKeys="model[key]" />
                <ACheckboxGroup v-else-if="item.tag === 'checkbox'" v-model:value="model[key]" :options="item.values" />
                <Phone v-else-if="item.tag === 'phone'" v-model:value="model[key]" />
                <SmallImageUploader v-else-if="item.tag === 'siu'" :style="item.style" v-model:value="model[key]" />
                <Address v-else-if="item.tag === 'address'" :style="item.style" v-model:value="model[key]" />
                <CardSelect v-else-if="item.tag === 'cardSelect'" :style="item.style" v-model:value="model[key]"
                    :options="item.values" />
                <AUpload v-else-if="item.tag === 'upload'" v-model:fileList="model[key]" listType="picture-card">
                    <div v-if="!Array.isArray(model[key]) || model[key].length < item.max">
                        <PlusOutlined />
                        <div style="margin-top: 8px">Upload</div>
                    </div>
                </AUpload>
                <ImageUploader v-else-if="item.tag === 'iu'" :max="item.max" v-model:value="model[key]"
                    :size="item.size" :multi="item.multi" />
                <ATextarea v-else-if="item.tag === 'textarea'" :maxleng="item.max" :rows="item.rows"
                    v-model:value="model[key]" />
                <div v-else-if="item.tag === 'editor'" style="border: 1px solid #ccc">
                    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" />
                    <Editor style="height: 500px; overflow-y: hidden;" v-model="model[key]"
                        :default-config="editorConfig" @onCreated="e => editorRef = e" />
                </div>
                <Price v-else-if="item.tag === 'price'" :price="item.price" :size="item.size" :color="item.color" />
                <AButton :type="item.type" v-else-if="item.tag === 'button'" @click="item.click" :size="item.size"
                    :style="item.style" :htmlType="item.htmlType">{{ item.text }}</AButton>
                <ATreeSelect v-else-if="item.tag === 'treeSelect'" :style="item.style" :tree-data="item.options"
                    v-model:value="model[key]" tree-checkable allow-clear show-checked-strategy="SHOW_PARENT"
                    @change="item.change"
                    :fieldNames="item.fields || { children: 'childList', label: 'shopCategoryName', value: 'shopCategoryId' }" />
                <span :style="item.style" class="text" v-else-if="item.tag === 'text'">{{ model[key] }}{{ item.text
                    }}</span>
                <RouterLink v-else-if="item.tag === 'link'" :to="item.to" @click="item.click">{{ item.text }}
                </RouterLink>
                <template v-else-if="item.tag === 'none'"></template>
                <component :is="item.tag" v-else :options="item.values" v-model:value="model[key]" :style="item.style"
                    :before="item.before" :after="item.after" />
                <span class="extra" v-if="item.extra">{{ item.extra }}</span>
                <slot v-if="$slots[key]" :name="key"></slot>
            </Space>
            <div class="extra" v-if="item.subExtra">{{ item.subExtra }}</div>
        </AFormItem>
        <AFormItem v-if="submit || reset || add || cancel">
            <div class="buttons" :style="space ? { paddingLeft: lw, paddingRight: lw } : {}">
                <AButton :loading="loading" type="primary" v-if="submit" htmlType="submit" @click="success">{{ typeof
        submit
        === 'string'
        ? submit : $t('ok')
                    }}</AButton>
                <AButton v-if="reset" @click="() => { formRef.resetFields(); $emit('submit'); }">{{ typeof reset ===
        'string'
        ? reset : $t('reset') }}
                </AButton>
                <AButton type="primary" v-if="add" @click="$emit('add')">{{ typeof add === 'string'
        ? add : $t('add') }}</AButton>
                <AButton v-if="cancel" @click="$emit('cancel')">{{ typeof cancel === 'string'
        ? cancel : $t('cancel') }}</AButton>
            </div>
        </AFormItem>
        <AFormItem v-if="$slots.append">
            <slot name="append"></slot>
        </AFormItem>
    </AForm>
</template>
<script>
export default {
    name: 'Form',
    inheritAttrs: false
}
</script>
<script setup>
import { computed, getCurrentInstance, ref, onBeforeUnmount, shallowRef } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import api from '@/api/user';

/**
 * 需要自校验必填项的组件
 */
const customRequiredTags = ['number', 'cardSelect', 'siu', 'iu', 'daterange', 'radio', 'phone', 'address', 'cascader', 'tree', 'treeSelect', 'select'];
/**
 * 替换规则消息
 */
const replaceMessage = (message, title, min, max) => message.replace('[t]', title).replace('[min]', min).replace('[max]', max);
/**
 * 是否为数字
 * @param value 
 */
const isNumber = value => typeof value === 'number';
/**
 * 是否为空
 */
const isEmpty = value => null === value || undefined === value || '' === value.toString().trim();

/**
 * form表单引用
 */
const formRef = ref();
/**
 * editor引用
 */
const editorRef = shallowRef();

const props = defineProps({
    /**
     * 按钮要间距
     */
    space: {
        type: Boolean,
        default: false
    },
    /**
     * 布局
     */
    layout: {
        type: String,
        default: 'horizontal'
    },
    /**
     * 模型数据
     */
    model: {
        type: Object,
        default: () => ({})
    },
    /**
     * 表单配置项
     */
    options: {
        type: Object,
        required: true
    },
    /**
     * label宽度
     */
    lw: {
        type: String,
        default: '80px'
    },
    /**
     * 提交按钮标题
     */
    submit: [Boolean, String],
    /**
     * 重置按钮标题
     */
    reset: [Boolean, String],
    /**
     * 添加按钮标题
     */
    add: [Boolean, String],
    /**
     * 取消按钮标题
     */
    cancel: [Boolean, String],
    /**
     * 提交loading
     */
    loading: Boolean
});

const emit = defineEmits(['success', 'add', 'cancel']);

/**
 * 编辑器配置项
 */
const editorConfig = {
    MENU_CONF: {
        uploadVideo: {
            async customUpload(file, insertFn) {
                try {
                    const [src] = await api.upload([file]);
                    insertFn(src, '', '');
                } catch (error) { }
            }
        },
        uploadImage: {
            async customUpload(file, insertFn) {
                try {
                    const [src] = await api.upload([file]);
                    insertFn(src, '', '');
                } catch (error) { }
            }
        }
    }
}

/**
 * 表单配置项
 */
const opts = computed(() => {
    const _options = { ...props.options };
    const { appContext: { config: { globalProperties: { $t } } } } = getCurrentInstance();

    for (const key in _options) {
        if (Object.prototype.hasOwnProperty.call(_options, key)) {
            const item = _options[key];
            const { title, sub } = item;
            item.tag = item.tag || 'input';
            item.ph = item.ph?.replace('{t}', title);
            item.rules = item.rules || [];
            if (item.required && !item.rules.some(c => c.required)) {
                item.rules.unshift({ required: true });
            }

            if (item.tag === 'checkbox' && Object.prototype.toString.call(item.values) === '[object Object]') {
                const values = [];
                for (const childkey in item.values) {
                    const child = item.values[childkey];
                    values.push({
                        label: child,
                        value: childkey
                    });
                }
                item.values = values;
            }

            for (const rule of item.rules) {
                rule.whitespace = true;
                rule.validateFirst = true;
                // 如果有必填并且是自定义验证组件的则处理
                const message = replaceMessage($t('required'), sub || title);
                if (rule.required && customRequiredTags.includes(item.tag)) {
                    rule.validator = (_, value) => {
                        if (isEmpty(value)) {
                            return Promise.reject(message);
                        }
                        if (Array.isArray(value)) {
                            if (!value.length) {
                                return Promise.reject(message);
                            }
                            for (const v of value) {
                                if (isEmpty(value)) {
                                    return Promise.reject(message);
                                }
                            }
                        }
                        return Promise.resolve();
                    }
                    continue;
                }
                if (rule.message) continue;
                const { min, max } = rule;
                if (rule.required) {
                    rule.message = message;
                }
                else if (isNumber(min) && isNumber(max)) {
                    rule.message = replaceMessage($t('minMax'), sub || title, min, max);
                }
                else if (isNumber(min)) {
                    rule.message = replaceMessage($t('min'), sub || title, min, max);
                }
                else if (isNumber(max)) {
                    rule.message = replaceMessage($t('max'), sub || title, min, max);
                }
                else if (rule.type === 'phone') {
                    rule.pattern = /^\+\d+$/
                    rule.message = replaceMessage($t('invalidFormat'), sub || title);
                }
                if (rule.type) {
                    rule.message = replaceMessage($t('invalidFormat'), sub || title);
                }
            }
        }
    }
    return _options;
});

/**
 * 提交
 */
const success = async () => {
    try {
        await formRef.value?.validate();
        emit('success', props.model);
    } catch (error) { }
}

/**
 * 校验
 */
const valid = async () => {
    try {
        const result = await formRef.value.validate();
        if (result) {
            return props.model;
        }
        return Promise.reject();
    } catch (error) {
        return Promise.reject();
    }
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
    editorRef.value?.destroy();
});

defineExpose({
    success,
    valid,
    validFields: names => formRef.value.validateFields(names)
});
</script>

<style lang="scss" scoped>
.buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.extra {
    white-space: nowrap;
    align-self: flex-end;
    color: #7F7F7F;
}

.text {
    padding-top: 6px;
    font-size: 16px;
}

.ant-form-horizontal {
    :deep .ant-form-item {
        .ant-form-item-label {
            white-space: normal;
        }
    }
}

.ant-form-inline {
    gap: 15px 30px;

    .ant-form-item {
        margin-right: 0;
    }
}
</style>