import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import components from './components';
import 'normalize.css';
import './styles/index.scss';
import 'ant-design-vue/dist/antd.css';
import 'dayjs/locale/ar';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';
import '@wangeditor/editor/dist/css/style.css';
import { dayjsExtends } from '@/utils/plugins';
import dayjs from 'dayjs';
import { i18nAddResources } from '@wangeditor/editor';

i18nAddResources('ar', {
    tableModule: { deleteCol: "حذف العمود", deleteRow: "احذف صف", deleteTable: "حذف الجدول", widthAuto: "العرض قابل للتكيف", insertCol: "إدراج عمود", insertRow: "إدراج الصف ", insertTable: "إدراج جدول", header: "رأس" },
    videoModule: { delete: "حذف الفيديو", uploadVideo: "رفع فيديو", insertVideo: "أدخل الفيديو", videoSrc: "عنوان الفيديو", videoSrcPlaceHolder: "<iframe> عنوان URL لملف الفيديو أو جهة خارجية ", videoPoster: "غلاف الفيديو", videoPosterPlaceHolder: "عنوان URL لصورة الغلاف", ok: "بالتأكيد", editSize: "تعديل الحجم", width: "عرض", height: "عالي" },
    uploadImgModule: { uploadImage: "تحميل الصور", uploadError: "{{fileName}} خطأ في التحميل" },
    listModule: { unOrderedList: "قائمة غير مرتبة", orderedList: "قائمة مرتبة" },
    common: { ok: "ديكأتلاب", delete: "حسمي", enter: "لخدي" },
    blockQuote: { title: "رعس" },
    codeBlock: { title: "ةيجمربلاتاميلعتلاةلتك" },
    color: { color: "طخلانول", bgColor: "ةيفلخلانول", default: "يضارتفلانوللا", clear: "حضاوةيفلخنول" },
    divider: { title: "ميسقتلاطخ" },
    emotion: { title: "ريبعت" },
    fontSize: { title: "طخلامجح", default: "يضارتفلاطخلامجح" },
    fontFamily: { title: "الخط", default: "يضارتفلاطخلا" },
    fullScreen: { title: "ةشاشلاريبكت" },
    header: { title: "ناونع", text: "صن" },
    image: { netImage: "بيولاةروص", delete: "ةروصلافذح", edit: "ةروصلاريرحت", viewLink: "ضرعلاطبار", src: "ةطيرخلاناونع", desc: "ةروصلافصو", link: "ةروصلاطبار" },
    indent: { decrease: " تقليل المسافة البادئة", increase: "زيادة المسافة البادئة" }, justify: { left: "راسيللةاذاحم", right: "نيميلاةاذاحم", center: "زكرملاةاذاحم", justify: "يملئ السطر" },
    lineHeight: { title: "ف ارتفاع", default: "ي الصف ارت" },
    link: { insert: "طبارلالخدأ", text: " الرابط نص", url: "طبارلاناونع", unLink: "إلغاء االرتباط", edit: "طبارلاليدعت", view: "ضرعلاطبار" },
    textStyle: { bold: "عريض", clear: "حضاوقيسنت", code: "مضمن رمز", italic: "مائل", sub: "ةطوطخم", sup: "عفترم", through: " يتوسطه خط", underline: "تسطير" },
    undo: { undo: " يغلي", redo: "ةداعإ" }, todo: { todo: "ةمداقلا" }
});

dayjs.extend(dayjsExtends);

createApp(App).use(store).use(router).use(components).use(i18n).mixin({
    methods: {
        /**
         * 截取图片
         */
        si(str) {
            return str?.split(',') ?? [];
        },
        /**
         * 取第一章图片
         */
        fi(str) {
            return this.si(str)[0];
        }
    }
}).mount('#app')
